import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../Css/surveydashboard.css';
import '../../Css/survey.css';
import { PieChart } from 'recharts';
import ENV from "../../config";
import Header from '../../Header/Header';
import LeftNav from '../../Components/LeftNav/LeftNav';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { TextField, Tooltip } from "@mui/material";
import LoaderDashboard from '../../Components/LoaderDashboard';
import { Icon, IconButton, ThemeProvider, createTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
  OutlinedInput, Button, Grid, Typography
} from '@mui/material';
import env from '../../config';
function CancelledSurveys() {
  const navigate = useNavigate();
  const location = useLocation();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const surveyMap = new Map();

  //sort surveys by creation date
  surveys
    .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
    .forEach((survey) => {
      const { companyName, ...rest } = survey;
      if (surveyMap.has(companyName)) {
        surveyMap.get(companyName).push({ companyName, ...rest });
      } else {
        surveyMap.set(companyName, [{ companyName, ...rest }]);
      }
    });


  // Initialize filter options from location state
  const initialcompany = location.state?.filterOptions?.company || [];
  const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
  const initialstatus = location.state?.filterOptions?.status || [];
  const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
  const initialCountries = location.state?.filterOptions?.country || [];
  const [selectedCountries, setSelectedCountries] = useState(initialCountries);
  const initialvessel = location.state?.filterOptions?.vessel || [];
  const [selectedVessels, setSelectedVessels] = useState(initialvessel);
  const [filteredSurveys, setFilteredSurveys] = useState([]);

  // State for available options
  const [availableVessels, setAvailableVessels] = useState([]);

  // Fetch surveys and template names on component mount
  useEffect(() => {
    fetchSurveys();
    // // handleToken();

  }, []);

  // Update available options and filter surveys when filters or surveys change
  useEffect(() => {
    updateAvailableOptions();
    filterSurveys();
  }, [selectedCompanies, selectedVessels, surveys]);


  const fetchSurveys = async () => {
    try {
      // const response = await axios.get(ENV.backend + '/api/survey');
      let response = []
      if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
        response = await axios.get(env.backend + "/api/survey/getDeletedSurveys");
      }
      console.log(response)
      response.data.map(item => {
        if (item.companyName === 'Cstor Maritime') {
          console.log(item)
        }
      })
      setSurveys(Object.values(response.data));
      setLoading(false);

    } catch (error) {
      console.error('Error fetching surveys:', error);
      setLoading(true);
      // alert("error fetching surveys")
      localStorage.clear()
      window.location.replace('/')
    }
  };


  useEffect(() => {
    filterSurveys();
  }, [selectedCompanies, selectedVessels]);

  const handleCompanyChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleVesselChange = (event) => {
    setSelectedVessels(event.target.value);
  };

  // Update available options based on selected filters
  const updateAvailableOptions = () => {
    let filteredCountries = [];
    let filteredVessels = [];
    let filteredStatuses = [];

    surveys.forEach((survey) => {
      if (selectedCompanies.length === 0 || selectedCompanies.includes(survey.companyName)) {
        if (!filteredCountries.includes(survey.countryName)) {
          filteredCountries.push(survey.countryName);
        }
        if (!filteredVessels.includes(survey.vesselName)) {
          filteredVessels.push(survey.vesselName);
        }
        survey.surveyCertificateList.forEach(cert => {
          if (!filteredStatuses.includes(cert.surveyCertificateStatus)) {
            filteredStatuses.push(cert.surveyCertificateStatus);
          }
        });
      }
    });

    setAvailableVessels(filteredVessels);
  };
  // Filter surveys based on selected filters
  const filterSurveys = () => {
    let surveys = [];

    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company => {
        if (surveyMap.has(company)) {
          surveys.push(...surveyMap.get(company));
        }
      });
    } else {
      surveyMap.forEach((value) => {
        surveys.push(...value);
      });
    }

    if (selectedStatuses.length > 0) {
      surveys = surveys.filter(survey =>
        survey.surveyCertificateList.some(cert =>
          selectedStatuses.includes(cert.surveyCertificateStatus)
        )
      );
    }

    if (selectedCountries.length > 0) {
      surveys = surveys.filter(survey =>
        selectedCountries.includes(survey.countryName)
      );
    }
    if (selectedVessels.length > 0) {
      surveys = surveys.filter(survey =>
        selectedVessels.includes(survey.vesselName)
      );
    }
    surveys = surveys.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

    console.log("Filtered surveys", surveys);
    setFilteredSurveys(surveys);
  };

  // Filter certificates based on selected statuses
  const getFilteredCertificates = (certificates) => {
    if (selectedStatuses.length === 0) {
      return certificates;
    }
    return certificates.filter(cert => selectedStatuses.includes(cert.surveyCertificateStatus));
  };


  const [OpenCertificateDialog, setOpenCertificateDialog] = useState(false);


  // State for managing template submission
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertificatesForReactivate, setSelectedCertificatesForReactivate] = useState([]);

  const handleCertificateButtonClick = (surveyId) => {
    setSelectedSurveyId(surveyId);
    const selectedSurvey = surveys.find(survey => survey.surveyId === surveyId);
    if (selectedSurvey) {
      setSelectedCertificates(getFilteredCertificates(selectedSurvey.surveyCertificateList));
    }
    setOpenCertificateDialog(true);
  };



  // Update filters based on URL parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.size !== 0) {
      console.log('urlparams', urlParams.getAll('company'));
      setSelectedCompanies(urlParams.get('company') ? urlParams.get('company').split(',') : []);
      setSelectedStatuses(urlParams.get('status') ? urlParams.get('status').split(',') : []);
      setSelectedCountries(urlParams.get('country') ? urlParams.get('country').split(',') : []);
      setSelectedVessels(urlParams.get('vessel') ? urlParams.get('vessel').split(',') : []);
    }
  }, [location.search]);

  const handleCertificateDialogClose = () => {
    setOpenCertificateDialog(false);
    // Step 1: Get the IDs from selectedCertificates
    const certIdsToRemove = selectedCertificates
      .map(cert => cert.surveyCertificateId)  // Extract surveyCertificateId
      .filter(certId => !selectedCertIds.includes(certId));  // Keep only those not in selectedCertIds

    // Step 2: Filter SelectedCertificatesForReactivate
    setSelectedCertificatesForReactivate(prevCertificates =>
      prevCertificates.filter(certId => !certIdsToRemove.includes(certId))  // Remove the IDs identified in Step 1
    );
  };
  const [selectedSurveys, setSelectedSurveys] = useState([]);

  useEffect(() => {
    // By default, select all surveys when the component loads
    setSelectedSurveys(surveys.map(survey => survey.surveyId));
    const allCertificateIds = surveys.flatMap(survey =>
      survey.surveyCertificateList.map(cert => cert.surveyCertificateId)
    );
    setSelectedCertificatesForReactivate(allCertificateIds);
  }, [surveys]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // If select all is checked, add all survey ids to selectedSurveys
      setSelectedSurveys(filteredSurveys.map(survey => survey.surveyId));
    } else {
      // If select all is unchecked, clear selectedSurveys
      setSelectedSurveys([]);
    }
  };

  const handleSelectSurvey = (surveyId) => {
    if (selectedSurveys.includes(surveyId)) {
      // Remove the surveyId from selectedSurveys if it's already selected
      setSelectedSurveys(selectedSurveys.filter(id => id !== surveyId));
    } else {
      // Add the surveyId to selectedSurveys if it's not selected
      setSelectedSurveys([...selectedSurveys, surveyId]);
    }
  };

  const isSelected = (surveyId) => selectedSurveys.includes(surveyId);

  const [selectedCertIds, setSelectedCertIds] = useState([]);

  useEffect(() => {
    // Select all certificates by default when the dialog opens
    setSelectedCertIds(
      selectedCertificates
        .map(cert => cert.surveyCertificateId)  // Extract IDs from selectedCertificates
        .filter(certId => selectedCertificatesForReactivate.includes(certId))  // Only keep IDs that are in SelectedCertificatesForReactivate
    );
  }, [selectedCertificates]);

  const handleSelectAllCertificates = (event) => {
    if (event.target.checked) {
      // If "Select All" is checked, add all certificate IDs to selectedCertIds
      setSelectedCertIds(selectedCertificates.map(cert => cert.surveyCertificateId));
    } else {
      // If "Select All" is unchecked, clear the selection
      setSelectedCertIds([]);
    }
  };

  const handleSelectCertificate = (surveyCertificateId) => {
    if (selectedCertIds.includes(surveyCertificateId)) {
      // Remove the surveyCertificateId if it is already selected
      setSelectedCertIds(selectedCertIds.filter(id => id !== surveyCertificateId));
    } else {
      // Add the surveyCertificateId if it is not selected
      setSelectedCertIds([...selectedCertIds, surveyCertificateId]);
    }
  };

  const isSelectedCertificates = (surveyCertificateId) => selectedCertIds.includes(surveyCertificateId);
  const [comment, setComment] = useState('');

  const handleCloseReactivate = () => {
    setComment('');  // Clear comment field
    navigate('/reactivate');
  }

  const handleConfirmReactivate = async () => {


    try {


      const surveysToReactivate = surveys
        .filter(survey => selectedSurveys.includes(survey.surveyId))  // Only include surveys with IDs in selectedSurveys
        .map(survey => ({
          surveyId: survey.surveyId,
          updatedBy: localStorage.getItem('userFullName'),
          updatedOn: new Date(),

          surveyCertificateList: survey.surveyCertificateList
            .filter(cert => selectedCertificatesForReactivate.includes(cert.surveyCertificateId))  // Only include certificates with IDs in selectedCertificates
            .map(cert => ({
              surveyCertificateId: cert.surveyCertificateId
            }))
        }));
      const surveyResponse = await axios.post(`${env.backend}/api/survey/reactivateSurveys`, surveysToReactivate);

      if (surveyResponse.status === 200) {
        console.log('Survey reactivated successfully:', selectedVessels);
        handleCloseReactivate();
      }
    } catch (error) {
      console.error('Error occurred while reactivating the surveys:', error);
      alert('An error occurred while reactivating the surveys.');
    }

  };
  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">

                    <div>

                      <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-7px', fontSize: '26px' }}>Deleted Surveys</div></h1>

                    </div>
                    {
                      loading ? (<LoaderDashboard />) : (
                        <Paper style={{ minHeight: '80vh' }}>
                          <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                            <Grid container spacing={2} style={{ display: 'contents' }}>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Companies</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedCompanies}
                                    onChange={handleCompanyChange}
                                    input={<OutlinedInput label="Companies" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {Array.from(surveyMap.keys()).sort((a, b) => a.localeCompare(b)).map((name) => (
                                      <MenuItem key={name} value={name} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }} >
                                        <Checkbox checked={selectedCompanies.indexOf(name) > -1} />
                                        <ListItemText primary={name} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>



                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Vessel Name</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedVessels}
                                    onChange={handleVesselChange}
                                    input={<OutlinedInput label="Vessel Name" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableVessels
                                      .slice().filter(i => i)
                                      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                      .map(id => (
                                        <MenuItem key={id} value={id} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }} >
                                          <Checkbox checked={selectedVessels.includes(id)} />
                                          <ListItemText primary={id} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                          </div>

                          <TableContainer component={Paper} sx={{ maxHeight: '65vh !important', overflowY: 'scroll' }} className="table-container">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell className='table-head-new'>
                                    <Checkbox
                                      color="primary"
                                      indeterminate={selectedSurveys.length > 0 && selectedSurveys.length < filteredSurveys.length}
                                      checked={selectedSurveys.length === filteredSurveys.length}
                                      onChange={handleSelectAll}
                                      inputProps={{ 'aria-label': 'select all surveys' }}
                                    />
                                  </TableCell>
                                  <TableCell className='table-head-new'>S.No</TableCell>
                                  <TableCell className='table-head-new'>Client Name</TableCell>
                                  <TableCell className='table-head-new'>Vessel Name</TableCell>
                                  {/* <TableCell className='table-head-new'>Client Id</TableCell> */}
                                  <TableCell className='table-head-new'>Survey Name</TableCell>
                                  <TableCell className='table-head-new'>Created Date</TableCell>
                                  <TableCell className='table-head-new'>Cancelled Date</TableCell>
                                  <TableCell className='table-head-new'>Actions</TableCell>

                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredSurveys.map((survey, index) => (
                                  <TableRow key={index}>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isSelected(survey.surveyId)}
                                        onChange={() => handleSelectSurvey(survey.surveyId)}
                                      />
                                    </TableCell>
                                    <TableCell className="table-cell-survey">{index + 1}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.companyName}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.vesselName}</TableCell>
                                    {/* <TableCell className="table-cell-survey">{survey.companyId}</TableCell> */}
                                    <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                    <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                    <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                    <TableCell className="table-cell-survey">
                                      <div style={{ width: '20%', display: 'flex', alignItems: 'center', gap: "8px" }}>
                                        <Tooltip title={"View Certificates"} >
                                          <IconButton sx={{ padding: '2px' }} onClick={() => handleCertificateButtonClick(survey.surveyId)}>
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>

                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div >
                            {/* {surveys.length !== 0 && <div style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            These are the surveys associated with this vessel.
                                                        </div>} */}
                             <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight: 'bold' }}>
                              Do you want to reactivate the selected surveys?
                            </div>
                           {/* <TextField
                              autoFocus
                              id="outlined-multiline-static"

                              multiline
                              label="Comment"
                              type="text"
                              fullWidth

                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              sx={{
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Segoe UI Historic',
                                },
                                '& .MuiInputLabel-shrink': {
                                  marginTop: '0px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontFamily: 'Segoe UI Historic',
                                }
                              }}
                            /> */}
                            <div className="btn-container-multiple" style={{ marginTop: '5px' }}>

                              <button
                                variant="contained" className="modal-submit-btn" onClick={handleConfirmReactivate}>
                                Reactivate
                              </button>
                              <button
                                className="modal-close-btn" variant="contained" onClick={handleCloseReactivate}>
                                Close
                              </button>
                            </div>

                          </div>




                          <Dialog open={OpenCertificateDialog} onClose={handleCertificateDialogClose} fullWidth maxWidth="md">
                            <DialogContent>

                              <Paper>
                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} >
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className='table-head-new-ex'>
                                          <Checkbox
                                            color="primary"
                                            indeterminate={selectedCertIds.length > 0 && selectedCertIds.length < selectedCertificates.length}
                                            checked={selectedCertIds.length === selectedCertificates.length}
                                            onChange={handleSelectAllCertificates}
                                            inputProps={{ 'aria-label': 'select all certificates' }}
                                            disabled={true}
                                          />
                                        </TableCell>
                                        <TableCell className='table-head-new-ex'>Certificate Name</TableCell>
                                        <TableCell className='table-head-new-ex' sx={{ paddingInlineStart: '0px !important', width: '45%' }}> Status</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedCertificates.map((cert, i) => (
                                        <TableRow>

                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              color="primary"
                                              checked={isSelectedCertificates(cert.surveyCertificateId)}
                                              onChange={() => handleSelectCertificate(cert.surveyCertificateId)}
                                            disabled={true}

                                            />
                                          </TableCell>
                                          <TableCell sx={{
                                            padding: '0px !important',
                                            paddingInlineStart: '10px !important',
                                            fontSize: '14px !important',
                                            borderBottom: '1px solid #e0e0e0'
                                          }}>

                                            {cert.certificateName}

                                          </TableCell>
                                          <TableCell sx={{
                                            padding: '0px 10px 0px 0px !important',
                                            paddingInlineStart: '0px !important',
                                            fontSize: '14px !important',
                                            borderBottom: '1px solid #e0e0e0'
                                          }}>

                                            {cert.surveyCertificateStatus}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            </DialogContent>
                            <DialogActions>
                              <div className="btn-container-multiple" style={{ marginTop: '0px', marginRight: '15px' }} >
                                <button className="modal-close-btn" onClick={handleCertificateDialogClose}>
                                  Close
                                </button>
                              </div>
                            </DialogActions>
                          </Dialog>
                        </Paper>
                      )}
                  </div>
                </div>
              </div>


            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default CancelledSurveys;
