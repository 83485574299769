import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState } from "react";
//import axios from 'axios';
import { useNavigate, useLocation } from "react-router";
//import EditorDataContext from '../Context/editorDataProvider';
import Select from "react-select";
import getS3Objects from '../S3Objects';
import getS3ObjectsPdf from '../S3ObjectsPdf';
import company from '../dataSource.json';
import axios from "axios";
import LeftNav from "../../Components/LeftNav/LeftNav";
import Header from "../../Header/Header";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Tooltip } from "@mui/material";
import handleToken from "../../Components/TokenHandler";


const TemplateList = () => {
  //const [template, setTemplate] = useState('TEST');

  // const company = [
  //  { value: "DHAKAR", label: "DHAKAR" },
  //  { value: "NEOCAP", label: "NEO CAP" },
  // { value: "BLUCAP", label: "BLU CAP" },
  //];

  const companyval = [
    { value: company.company.CompanyId, label: company.company.CompanyName }

  ];
  const demo = { value: company.company.CompanyId, label: "TRIAL" }
  const [selectOption, setSelectOption] = useState(companyval[0]); // Set default option as the first option in the options array
    const [error, setError] = useState(""); // Initialize state for error messages
    
    // Initialize state for S3 data and PDF data
    const [s3Data, setS3Data] = useState([]);
    const [s3DataPdf, setS3DataPdf] = useState([]);

    const [selectedTemplateName, setSelectedTemplateName] = useState("");  // Initialize state for selected template name

  const navigate = useNavigate();
  const location = useLocation();

    // Define routes based on location state or set default paths
    const toeditor = location.state?.from?.pathname || "/cert-editor";
    const topdfpreviewer = location.state?.from?.pathname || "/pdf-previewer";
    const errorpage = location.state?.from?.pathname || "/error";
    const goback = location.state?.from?.pathname || "/";

    // Sort and prepare S3 data for display
  const sortedData = s3Data?.map((object) => ({ value: object, label: object }))
    .sort((a, b) => a.label.localeCompare(b.label));

    console.log(sortedData); // Log sorted data for debugging

  // testdummydata for pdf and html templates
  const allowedFiles = [
    "NCS-CL-PR-NEW.html",
    "NCS-BC-PR-NEW.html",
    "NCS-CRT-PR-NEW.html",
    "NCS-IA-PR-NEW.html",
    "NCS-IBC-PR-NEW.html",
    "DEMOCC.html"
  ];

  const filteredData = s3Data
    ?.filter((object) => allowedFiles.includes(object))
    .map((object) => ({ value: object, label: object }));

  console.log(filteredData);

  const allowedPdfFiles = [
    "NCS-CL-PR-NEW.pdf",
    "NCS-BC-PR-NEW.pdf",
    "NCS-CRT-PR-NEW.pdf",
    "NCS-IA-PR-NEW.pdf",
    "NCS-IBC-PR-NEW.pdf",
    "DEMOCC.pdf"

  ];

  const filteredPdfData = s3DataPdf
    ?.filter((object) => allowedPdfFiles.includes(object))
    .map((object) => ({ value: object, label: object }));

  // console.log(filteredPdfData);

    // Handle changes in selected template value
    const handleValueChange = (value) => {
        console.log(value); // Log selected value for debugging
        setSelectedTemplateName(value); // Update selected template name

    try {
      console.log(value);
        const isPDF = value.value.endsWith(".pdf"); // Determine if the selected template is a PDF
        console.log(value.value); // Log the selected template name
      console.log(isPDF);

      isPDF ? handlePDF(value.value) : handleHTML(value.value);
      //navigate(toeditor,{state:{data: text,company: selectedOption.value  }});
    } catch (e) {
      console.log(" errored");
      setError("errord");
      console.log(e);
      navigate(errorpage, { replace: true });
    }

  };
  useEffect(() => {
    // handleToken();
  })
  // const handleSelect = async (value) => {

    // Fetch S3 data when the component mounts or selectOption changes
    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getS3Objects("", 'DAKAR');
        console.log(response);
        setS3Data(response);

        try {
          const responsePdf = await getS3ObjectsPdf("", 'DAKAR');
          console.log(responsePdf);
          setS3DataPdf(responsePdf);
        } catch (error) {
          // Handle error
          console.log('Error:', error);
        }
      } catch (error) {
        // Handle error
        console.log('Error:', error);
      }
    };

    fetchData();
  }, [selectOption]);

    // Handle navigation to PDF previewer with selected template
  const handlePDF = (templateName) => {
    try {
      navigate(topdfpreviewer, {
        state: {
          filename: templateName,
          company: "DAKAR",
        },
      });
    } catch (e) {
        // Handle errors and navigate to the error page
      console.log(" errored");
      setError("errored");
      console.log(e);
      navigate(errorpage, { replace: true });
    }
  };

    // Handle navigation to HTML editor with selected template
  const handleHTML = (templateName) => {
    try {
      navigate(toeditor, {
        state: { data: templateName, company: "DAKAR", flow: "update" },
      });
    } catch (e) {
        // Handle errors and navigate to the error page
      console.log(" errored");
      setError("errord");
      console.log(e);
      navigate(errorpage, { replace: true });
    }
    };

    // Handle form submission
  const handleSubmit = async (e) => {
    try {
      console.log(selectedTemplateName);
      const isPDF = selectedTemplateName.value.endsWith(".pdf");
      console.log(selectedTemplateName.value);
      console.log(isPDF);

      isPDF ? handlePDF() : handleHTML();
      //navigate(toeditor,{state:{data: text,company: selectedOption.value  }});
    } catch (e) {
      console.log(" errored");
      setError("errord");
      console.log(e);
      navigate(errorpage, { replace: true });
    }
  };
    // Handle closing the form and navigating to a previous page
    function handleClose(event) {
        navigate(goback);
    }

    // Handle navigation to the template creation page
    const handleCreateTemplate = () => {
        window.location.href = '/choose-template';
    }

  return (
    <div>
      <Header
      />
      
      <div className="container-fluid">
        <Tabs className="vertical-tabs">
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <div class="card-listing-section">
              
              <div class="ml-3">
                <div class="right-wrapper" style={{ minHeight: '50vh' }}>
                  <div style={{
                    display: "flex",
                    
                    justifyContent: "space-between",
                    backgroundColor: '#e5fffd',borderRadius:'14px 10px 0px 0px'
                  }}>
                    <div div clas="cyantitle" >
                    <h1 className="heading-survey">Templates</h1>
                    </div>
                    <Tooltip title="Add New Template" arrow>
                      <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreateTemplate} />
                    </Tooltip>
                  </div>
                  <label htmlFor="roles" className="heading-template"><b>Company:</b></label>
                  <div >
                    <Select
                      options={companyval}  className="select-template"
                      defaultValue={demo}
                      // onChange=  {handleSelect}//{setSelectedOption}
                      isDisabled={true}

                    />
                  </div>

                  <label className="heading-template">
                    <b>Template Name:</b>
                  </label>

                  <Select
                    options={filteredData}  className="select-template"
                    selectOption={selectedTemplateName}
                    onChange={handleValueChange}
                  />

                  <label className="heading-template"> <b>View PDF:</b>

                  </label>
                  <Select 
                    options={filteredPdfData}
                    selectOption={selectedTemplateName}
                    onChange={handleValueChange}  className="select-template"
                  />
                  <p>{error}</p>
                  {/* <div class="btn-container-multiple">
                    <button className="modal-submit-btn">
                      <b>Submit</b>
                    </button>
                  </div> */}
                  {/* <button className="modal-close-btn" onClick={handleClose}>
                    <b>Close</b>
                  </button> */}
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>


    </div >
  );
};

export default TemplateList;
