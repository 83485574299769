import React, { useState, useEffect } from 'react';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './DeleteVessel.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import axios from "axios";
import env from '../../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, TextField, Tooltip } from "@mui/material";
import LoaderDashboard from '../../LoaderDashboard';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
    OutlinedInput, Button, Grid, Typography
} from '@mui/material';

const DeleteVessel = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [surveyId, setSurveyId] = useState();
    const surveyMap = new Map();

    //sort surveys by creation date
    surveys
        .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
        .forEach((survey) => {
            const { companyName, ...rest } = survey;
            if (surveyMap.has(companyName)) {
                surveyMap.get(companyName).push({ companyName, ...rest });
            } else {
                surveyMap.set(companyName, [{ companyName, ...rest }]);
            }
        });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(2);

    // Calculate pagination indices
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const totalPages = Math.ceil(surveyMap.size / itemsPerPage);

    // Initialize filter options from location state
    const initialcompany = location.state?.filterOptions?.company || [];
    const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
    const initialstatus = location.state?.filterOptions?.status || [];
    const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
    const initialCountries = location.state?.filterOptions?.country || [];
    const [selectedCountries, setSelectedCountries] = useState(initialCountries);
    const initialvessel = location.state?.vesselData || [];
    const [selectedVessels, setSelectedVessels] = useState(initialvessel);
    const [comment, setComment] = useState('');
    // Fetch surveys and template names on component mount
    useEffect(() => {
        fetchSurveys();
        fetchTemplateNames();
        // // handleToken();

    }, []);



    const fetchSurveys = async () => {
        try {
            // const response = await axios.get(ENV.backend + '/api/survey');
            let response = []
            if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
                response = await axios.get(env.backend + "/api/survey");
            }
            else {
                const userId = localStorage.getItem('userFullName')
                response = await axios.get(env.backend + "/api/survey/getSurveys/" + userId);
            }

            const filteredSurveys = Object.values(response.data).filter(survey => survey.vesselName === selectedVessels?.vesselName);
            setSurveys(filteredSurveys);

            setLoading(false);

        } catch (error) {
            console.error('Error fetching surveys:', error);
            setLoading(true);
            // alert("error fetching surveys")
            localStorage.clear()
            window.location.replace('/')
        }
    };



    // Filter certificates based on selected statuses
    const getFilteredCertificates = (certificates) => {
        if (selectedStatuses.length === 0) {
            return certificates;
        }
        return certificates.filter(cert => selectedStatuses.includes(cert.surveyCertificateStatus));
    };

    // States for template management
    const [templateNames, setTemplateNames] = useState([]);
    const [OpenCertificateDialog, setOpenCertificateDialog] = useState(false);

    const fetchTemplateNames = async () => {
        try {
            const templateNamesResponse = await axios.get(env.backend + "/api/template");
            const templates = Object.values(templateNamesResponse.data);
            console.log('Fetched Template Names:', templates);
            setTemplateNames(templates);
        } catch (error) {
            console.error("Error fetching template names:", error);
        }
    };


    const [selectedSurveyId, setSelectedSurveyId] = useState(null);
    const [selectedCertificates, setSelectedCertificates] = useState([]);

    const handleCertificateButtonClick = (surveyId) => {
        setSelectedSurveyId(surveyId);
        const selectedSurvey = surveys.find(survey => survey.surveyId === surveyId);
        if (selectedSurvey) {
            setSelectedCertificates(getFilteredCertificates(selectedSurvey.surveyCertificateList));
        }
        setOpenCertificateDialog(true);
    };


    // Update filters based on URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.size !== 0) {
            console.log('urlparams', urlParams.getAll('company'));
            setSelectedCompanies(urlParams.get('company') ? urlParams.get('company').split(',') : []);
            setSelectedStatuses(urlParams.get('status') ? urlParams.get('status').split(',') : []);
            setSelectedCountries(urlParams.get('country') ? urlParams.get('country').split(',') : []);
            setSelectedVessels(urlParams.get('vessel') ? urlParams.get('vessel').split(',') : []);
        }
    }, [location.search]);

    const handleCertificateDialogClose = () => {
        setOpenCertificateDialog(false);
    };
    const handleCloseDelete = () => {
        setComment('');  // Clear comment field
        navigate('/vessel', {
            state: {
                search: location.state?.search
            }
        });
    }
    const handleConfirmDelete = async () => {
        if (comment.trim() === '') {
            alert('Comment is required to delete the vessel.');
            return;
        }
    
        try {
          
            const surveysToDelete = surveys.map(survey => ({
                surveyId: survey.surveyId, 
                updatedBy: localStorage.getItem('userFullName'),
                updatedOn: new Date(),
                deleteReason: comment,
                surveyCertificateList: getFilteredCertificates(survey.surveyCertificateList).map(cert => ({
                    surveyCertificateId:cert.surveyCertificateId
                    }
                ))
            }));
            
            console.log('Surveys to Delete:', surveysToDelete); 
    
           
            const surveyResponse = await axios.post(`${env.backend}/api/survey/deleteSurveys`, surveysToDelete);
    
            if (surveyResponse.status === 200) {
                try {
                    // Send delete request to the backend for the vessel
                    const vesselResponse = await axios.put(`${env.backend}/api/vessel/deleteVessel`, {
                        id: selectedVessels.id,
                        status: "Deleted",
                        deleteReason: comment,
                        updatedBy: localStorage.getItem('userFullName'),
                        updatedOn: new Date(),
                    });
    
                    if (vesselResponse.status === 200) {
                        console.log('Vessel deleted successfully:', selectedVessels);
                        handleCloseDelete();
                    } else {
                        console.error('Failed to delete the vessel:', vesselResponse.status);
                        alert('Failed to delete the vessel.');
                    }
                } catch (error) {
                    console.error('Error occurred while deleting the vessel:', error);
                    alert('An error occurred while deleting the vessel.');
                }
            } else {
                console.error('Failed to delete the surveys:', surveyResponse.status);
                alert('Failed to delete the surveys.');
            }
        } catch (error) {
            console.error('Error occurred while deleting the surveys:', error);
            alert('An error occurred while deleting the surveys.');
        }
    };
    
    

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">

                                        <div>

                                            <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-7px', fontSize: '26px' }}>Delete {selectedVessels.vesselName}</div></h1>
                                            <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-23px', fontSize: '15px' }}>Client Name : {selectedVessels.companyName}</div></h1>



                                        </div>
                                        {
                                            loading ? (<LoaderDashboard />) : (
                                                <Paper style={{ minHeight: '80vh' }}>
                                                    <div className="search-bar-container" style={{ marginTop: '-23px' }}>

                                                    </div>

                                                    {surveys.length !== 0 ? (
                                                        <TableContainer component={Paper} sx={{ maxHeight: '55vh' }} className="table-container">
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className='table-head-new'>S.No</TableCell>
                                                                        {/* <TableCell className='table-head-new'>Client Id</TableCell> */}
                                                                        <TableCell className='table-head-new'>Survey Name</TableCell>
                                                                        <TableCell className='table-head-new'>Created Date</TableCell>
                                                                        <TableCell className='table-head-new'>Created By</TableCell>
                                                                        <TableCell className='table-head-new'>Certificates</TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {surveys.map((survey, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell className="table-cell-survey">{index + 1}</TableCell>
                                                                            {/* <TableCell className="table-cell-survey">{survey.companyId}</TableCell> */}
                                                                            <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                                                            <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                                                            <TableCell className="table-cell-survey">{survey.createdBy}</TableCell>
                                                                            <TableCell className="table-cell-survey">
                                                                                <div style={{ width: '20%', display: 'flex', alignItems: 'center', gap: "8px" }}>

                                                                                    <Tooltip title={"View Certificates"}>
                                                                                        <IconButton sx={{ padding: '2px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleCertificateButtonClick(survey.surveyId)}>
                                                                                            <span style={{ textDecoration: 'underline' }}>{survey?.surveyCertificateList?.length}</span>
                                                                                        </IconButton>
                                                                                    </Tooltip>


                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (
                                                        <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight: 'bold' }}>
                                                            There is no associated surveys with this vessel.
                                                        </div>
                                                    )}
                                                    <div >
                                                        {/* {surveys.length !== 0 && <div style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            These are the surveys associated with this vessel.
                                                        </div>} */}
                                                        <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight: 'bold' }}>
                                                            Do you want to delete this vessel? Please provide a reason in the comment box below.
                                                        </div>
                                                        <TextField
                                                            autoFocus
                                                            id="outlined-multiline-static"

                                                            multiline
                                                            label="Comment"
                                                            type="text"
                                                            fullWidth

                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)}
                                                            sx={{
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                },
                                                                '& .MuiInputLabel-shrink': {
                                                                    marginTop: '0px',
                                                                },
                                                                '& .MuiOutlinedInput-input': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                }
                                                            }}
                                                        />
                                                        <div className="btn-container-multiple" style={{ marginTop: '5px' }}>

                                                            <button
                                                                variant="contained" className="modal-submit-btn" disabled={comment.trim() === ''} onClick={handleConfirmDelete}>
                                                                Delete
                                                            </button>
                                                            <button
                                                                className="modal-close-btn" variant="contained" onClick={handleCloseDelete}>
                                                                Close
                                                            </button>
                                                        </div>

                                                    </div>

                                                    <Dialog open={OpenCertificateDialog} onClose={handleCertificateDialogClose} fullWidth maxWidth="md">
                                                        <DialogContent>

                                                            <Paper>
                                                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} >
                                                                    <Table stickyHeader aria-label="sticky table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className='table-head-new-ex'>Certificate Name</TableCell>
                                                                                <TableCell className='table-head-new-ex' sx={{ paddingInlineStart: '0px !important', width: '45%' }}> Status</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell sx={{
                                                                                    padding: '0px !important',
                                                                                    paddingInlineStart: '10px !important',
                                                                                    fontSize: '14px !important',
                                                                                    borderBottom: '1px solid #e0e0e0'
                                                                                }}>
                                                                                    {selectedCertificates.map((cert, i) => (
                                                                                        <div
                                                                                            className="certificate-name"
                                                                                            key={i}
                                                                                            style={{
                                                                                                borderBottom: i < selectedCertificates.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                                                                padding: '4px 0'
                                                                                            }}
                                                                                        >
                                                                                            {cert.certificateName}
                                                                                        </div>
                                                                                    ))}
                                                                                </TableCell>
                                                                                <TableCell sx={{
                                                                                    padding: '0px 10px 0px 0px !important',
                                                                                    paddingInlineStart: '0px !important',
                                                                                    fontSize: '14px !important',
                                                                                    borderBottom: '1px solid #e0e0e0'
                                                                                }}>
                                                                                    {selectedCertificates.map((cert, i) => (
                                                                                        <div
                                                                                            className="certificate-status"
                                                                                            key={i}
                                                                                            style={{
                                                                                                borderBottom: i < selectedCertificates.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                                                                padding: '4px 0'
                                                                                            }}
                                                                                        >
                                                                                            {cert.surveyCertificateStatus}
                                                                                        </div>
                                                                                    ))}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Paper>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <div className="btn-container-multiple" style={{ marginTop: '0px', marginRight: '15px' }} >
                                                                <button className="modal-close-btn" onClick={handleCertificateDialogClose}>
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Paper>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>

        </div>
    );
}
export default DeleteVessel;
