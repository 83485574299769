import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../../config";
import CertEditor from './CertEditor';
import Button from '@mui/material/Button';
import handleToken from '../../Components/TokenHandler';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import Header from '../../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


const { Open } = Plugins;


//const PdfPreview = ({ filename, companyname }) => {
const PdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const toeditor = location.state?.from?.pathname || "/cert-editor";
  const filename = location.state.filename || "/cert-editor";
  const companyname = location.state.company || "/cert-editor";
  const totemplatelist = location.state?.from?.pathname || "/list-templates"
  const [companyNames, setCompanyName] = useState(null)
  const [templateNames, setTemplateName] = useState(null)
  const flow = location.state?.flow

  useEffect(() => {
    // handleToken();
  })

  // Fetch PDF URL when component mounts or when filename or companyname changes
  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {

        // Redirect if filename or companyname is missing
        if (!filename || !companyname) {
          navigate(totemplatelist);
          return;
        }

        // Update state with current filename and companyname
        setTemplateName(filename)
        setCompanyName(companyname)
        console.log(filename, companyname);
        // const response = await fetch(`http://localhost:8081/api/pdfpreview/${filename}/${companyname}`);

        // Fetch PDF preview from the backend
        const response = await axios.get(ENV.backend + '/api/pdfpreview', {
          //const response = await axios.get('http://44.207.146.240/api/pdfpreview', {
          params: {
            templateName: filename,
            companyName: companyname
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;

          // Handle native platform scenario
          if (Capacitor.isNativePlatform()) {
            savePdfToFile(blob);
            console.log("Insode isnative platform");
          }

          // Create a URL object for the PDF and set it in state
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };

    fetchPdfUrl();
  }, [filename, companyname]);


  // Function to save PDF to a file on native platforms
  const savePdfToFile = async (blob) => {
    // Convert the blob to a base64 string
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1]; // Remove the data URL prefix

      // Save the PDF file locally
      const filePath = 'downloaded.pdf';
      const directory = Directory.Documents;

      await Filesystem.writeFile({
        path: filePath,
        data: base64String,
        directory,
      });

      // Open the PDF using the default viewer
      openPdfFile();
    };
  };

  // Function to open the saved PDF file
  const openPdfFile = async () => {
    try {
      const filePathOld = "file:///storage/emulated/0/Documents/downloaded.pdf";
      await FileOpener.openFile({
        path: filePathOld,
      });
    } catch (error) {
      console.error('Error opening PDF:', error);
    }
  };

  // Function to handle closing the preview
  const handleClose = () => {
    console.log("inside close", templateNames, companyNames);
    if (flow === "ckeditor") {
      const temp = templateNames
      const htmlContent = temp.replace(/\.pdf$/, '.html');

      // Navigate to the editor with updated content and flow type
      navigate(toeditor, {
        state: { data: htmlContent, company: companyNames, flow: "update" },
      });
    } else {
      navigate(totemplatelist); // Navigate back to the list of templates
    }

  };







  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid container-fluid-ex">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          {/* <LeftNav /> */}
          <TabPanel style={{ width: "100%", marginRight: "2%", marginLeft: "2%" }}>
            <main>
              <div>
                <div class="active-heading" >
                  Template Name : {filename || 'Editor'}</div>
              </div>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper " style={{ margin: "0", maxHeight: '85vh' }}>
                    <div>
                      <div className="editor-container">
                        {pdfUrl ? (
                          <div style={{ width: '100%' }}>
                            <embed
                              src={pdfUrl}
                              title="PDF Preview"
                              style={{ width: '100%', height: '77vh', border: 'none' }}
                            />
                          </div>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                      <div class="btn-container-multiple" style={{ marginTop: '0px' }}>
                        <button
                          class="modal-close-btn" style={{ height: '28.5px',padding:'0' }} onClick={handleClose}><b> {flow === "ckeditor" ? "Close" : "Close"}</b></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div >
  );
};

export default PdfPreview;
