import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button } from '@mui/material';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './CompanyList.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import getCompany from '../../../Pages/Company';
import Snackbars from '../../Snackbar';


const CompanyTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState(location.state?.search || '');
    const [companies, setCompanies] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [openCreateSnackbar, setOpenCreateSnackbar] = useState();
    const [message, setMessage] = useState();


    // Function to handle closing the snackbar notification
    const handleCloseCreateSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;  // Prevent closing the snackbar when clicking outside of it
        }
        setOpenCreateSnackbar(false);  // Close the snackbar
    };
    // Effect to fetch company data when the component mounts
    useEffect(() => {
        fetchCompanies();
    }, []);  // Empty dependency array means this effect runs once after the initial render

    // Effect to show the snackbar notification if a company was just created
    useEffect(() => {
        if (location.state?.data?.create) {
            setOpenCreateSnackbar(true)
            setMessage("Client Created Successfully!!")
        }
        else if(location.state?.data?.update){
            setOpenCreateSnackbar(true)
            setMessage("Client Updated Successfully!!")
        }

    }, []);

    // Function to handle changes in the search input
    const handleSearchChange = (event) => {
        setSearch(event.target.value);  // Update search state with the current input value
    };

    // Function to fetch the list of companies from the API
    const fetchCompanies = async () => {
        try {
            const response = await getCompany("");  // Call to API utility function to fetch companies
            setCompanies(response);  // Reverse the order for display purposes
            // setSelectedCompany(dummy)
            setIsLoading(false);  // Set loading state to false after fetching data
        } catch (error) {
            // Handle error
            console.log("Error:", error);  // Log error to the console
            setIsLoading(false);  // Set loading state to false if there's an error
        }
    };

    // Filter companies based on the search input
    const filteredData = companies?.length > 0
        ? companies?.filter((item) =>
            item?.companyName?.toLowerCase().includes(search.toLowerCase())
            // || item?.email.toLowerCase().includes(search.toLowerCase())
        ) : [];  // Return an empty array if no companies are available

    // Function to navigate to the page for creating a new company
    const handleCreateNewCompany = () => {
        navigate('/addcompany');
    };

    // Function to navigate to the page for viewing details of a specific company
    const handleViewCompany = (data) => {
        navigate('/viewcompany', {
            state: {
                data: data,  // Pass company data to the view company page
                search: search
            }
        });
    };

    // Function to navigate to the page for editing a specific company
    const handleEditCompany = (data) => {
        navigate('/addcompany', {
            state: {
                data: data,  // Pass company data to the add company page
                isUpdate: true, // Flag to indicate that this is an update operation
                search: search
            }
        });
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">

                                    <div class="right-wrapper">
                                        <div >
                                            <div style={{ backgroundColor: '#e5fffd',borderRadius:'10px 10px 0px 0px'}} >
                                            <h1 class="titlereg" style={{ marginLeft: '-21px' }}> Clients</h1>
                                            </div>
                                            <Paper>
                                                <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                    <TextField
                                                        className="search-bar"
                                                        label="Search"
                                                        variant="outlined"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Search />
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI',
                                                            }
                                                        }}
                                                    />

                                                    <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreateNewCompany} />
                                                </div>
                                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} className="table-container" >
                                                    <Table>
                                                        <TableHead className="table-header">
                                                            <TableRow>
                                                                <TableCell className='table-head-new' style={{ width: "28%" }}>Name</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "28%" }}>Email</TableCell>

                                                                <TableCell className='table-head-new' style={{ width: "15%" }}>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filteredData.map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell className="table-cell-survey">{row.companyName}</TableCell>
                                                                    <TableCell className="table-cell-survey">{row.email}</TableCell>

                                                                    <TableCell className="table-cell-survey">
                                                                        <div className="action-buttons">
                                                                            <IconButton className="action-button" sx={{ padding: '2px' }} onClick={() => handleViewCompany(row)}>
                                                                                <Visibility />
                                                                            </IconButton>
                                                                            <IconButton className="action-button" sx={{ padding: '2px' }} onClick={() => handleEditCompany(row)}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
            <Snackbars
                openSnackbar={openCreateSnackbar}
                handleCloseSnackbar={handleCloseCreateSnackbar}
                type="success"
                message={message}
            />
        </div>

    );
};

export default CompanyTable;
