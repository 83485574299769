import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SurveyComponent from "../../Components/SurveyComponent";
import ENV from "../../config";
import Loader from "../../Components/Loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleToken from "../../Components/TokenHandler";
import LoaderDashboard from "../../Components/LoaderDashboard";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
  OutlinedInput, Button, Tooltip, IconButton, Grid
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import env from "../../config";


function SurveyorDashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMoreStates, setViewMoreStates] = useState({});
  const initialcompany = location.state?.filterOptions?.company || [];
  const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
  const [selectedSurveyNames, setSelectedSurveyNames] = useState([]);
  const initialstatus = location.state?.filterOptions?.status || [];
  const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [statusSelectionOrder, setStatusSelectionOrder] = useState([]);
  const initialCountries = location.state?.filterOptions?.country || [];
  const [selectedCountries, setSelectedCountries] = useState(initialCountries);
  const initialvessel = location.state?.filterOptions?.vessel || [];
  const [selectedVessels, setSelectedVessels] = useState(initialvessel);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableVessels, setAvailableVessels] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);

  const goback = location.state?.from?.pathname || "/"; // Path to navigate back to if available

  // Fetch surveys when the component mounts
  useEffect(() => {
    fetchSurveys();
    // handleToken();
  }, []);

  // Update available options and filter surveys whenever the filter selections or surveys change
  useEffect(() => {
    updateAvailableOptions();
    filterSurveys();
  }, [selectedCompanies, selectedStatuses, selectedCountries, selectedVessels, surveys]);
  // const handleClose = () => {
  //   navigate(goback);
  // };

  // Toggle the view more state for a specific survey
  const toggleViewMore = (key) => {
    setViewMoreStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Fetch surveys from the backend
  const fetchSurveys = async () => {
    try {
      let response = []
      if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
        response = await axios.get(env.backend + "/api/survey");
      }
      else {
        const userId = localStorage.getItem('userFullName')
        response = await axios.get(env.backend + "/api/survey/getSurveys/" + userId);
      }
      // const response = await axios.get(ENV.backend + "/api/survey");
      // console.log(Object.values(response.data)); // Log the response data
      const nonDeletedSurveys = Object.values(response.data).filter(survey => survey.surveyStatus !== "Deleted");
      setSurveys(nonDeletedSurveys);
      setLoading(false); // Update loading state
    } catch (error) {
      console.error("Error fetching surveys:", error); // Log the error
      setLoading(true); // Update loading state
      localStorage.clear() // Clear local storage on error
      window.location.replace('/') // Redirect to home
    }
  };



  // Create a map of surveys grouped by company name
  const surveyMap = new Map();
  surveys.forEach((survey) => {
    const { companyName, ...rest } = survey;
    if (surveyMap.has(companyName)) {
      surveyMap.get(companyName).push({ companyName, ...rest });
    } else {
      surveyMap.set(companyName, [{ companyName, ...rest }]);
    }
  });

  // Navigate back to the previous page or default route
  const closeDashboard = () => {
    navigate(goback);
  };

  // const [selectedCompanies, setSelectedCompanies] = useState([]);
  //const [selectedSurveyNames, setSelectedSurveyNames] = useState([]);
  // const [selectedStatuses, setSelectedStatuses] = useState([]);
  //const [filteredSurveys, setFilteredSurveys] = useState([]);

  // Arrays to store distinct survey attributes for filters
  const companyNames = Array.from(surveyMap.keys());
  const surveyNames = surveys.map(survey => survey.surveyName);
  const statusOptions = ["New", "In-Progress", "Completed", "Rejected"];
  const countryNames = Array.from(new Set(surveys.map(survey => survey.countryName)));
  const vesselNames = [...new Set(surveys.map(survey => survey.vesselName))];

  useEffect(() => {
    filterSurveys();

  }, [selectedCompanies, selectedStatuses, surveys, selectedCountries, selectedVessels]);

  // Event handler for changing selected companies
  const handleCompanyChange = (event) => {
    // event.target.value should be an array of selected companies
    setSelectedCompanies(event.target.value);
  };

  // Event handler for changing selected survey names
  const handleSurveyNameChange = (event) => {
    // event.target.value should be an array of selected survey names
    setSelectedSurveyNames(event.target.value);
  };

  // Event handler for changing selected countries
  const handleCountryChange = (event) => {
    // event.target.value should be an array of selected countries
    setSelectedCountries(event.target.value);
  };

  // Event handler for changing selected vessels
  const handleVesselChange = (event) => {
    // event.target.value should be an array of selected vessels
    setSelectedVessels(event.target.value);
  };
  // Event handler for changing selected statuses
  const handleStatusChange = (event) => {
    // event.target.value should be an array of selected statuses
    const value = event.target.value;
    setSelectedStatuses(value);

    // Update the order of statuses to ensure proper sorting in the UI
    const newOrder = value.reduce((acc, status) => {
      if (!acc.includes(status)) {
        acc.push(status);
      }
      return acc;
    }, []);
    setStatusSelectionOrder(newOrder);  // Set the status selection order
  };

  // Function to update available filter options based on current filters
  const updateAvailableOptions = () => {
    let filteredSurveys = surveys; // Start with the full list of surveys

    // Filter surveys by selected companies
    if (selectedCompanies.length > 0) {
      filteredSurveys = filteredSurveys.filter(survey => selectedCompanies.includes(survey.companyName));
    }

    // Update available countries based on filtered surveys
    setAvailableCountries([...new Set(filteredSurveys.map(survey => survey.countryName))]);
    // Update available vessels based on filtered surveys
    setAvailableVessels([...new Set(filteredSurveys.map(survey => survey.vesselName))]);

    // Determine available statuses based on certificates in filtered surveys
    const availableStatusesSet = new Set();
    filteredSurveys.forEach(survey => {
      survey.surveyCertificateList.forEach(cert => {
        availableStatusesSet.add(cert.surveyCertificateStatus); // Collect unique statuses
      });
    });
    setAvailableStatuses([...availableStatusesSet]); // Update available statuses
  };


  // Filter surveys based on selected filters and sort them
  const filterSurveys = () => {
    let surveys = [];

    // Collect surveys based on selected companies
    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company => {
        if (surveyMap.has(company)) {
          surveys.push(...surveyMap.get(company));
        }
      });
    } else {
      surveyMap.forEach((value) => {
        surveys.push(...value);
      });
    }
    // Filter surveys by selected statuses
    if (selectedStatuses.length > 0) {
      surveys = surveys.filter(survey =>
        survey.surveyCertificateList.some(cert => {
          const isRejected = selectedStatuses.includes("Rejected") && cert.surveyCertificateStatus === "In-Progress" && cert.comment !== null;
          const isSelectedStatus = selectedStatuses.includes(cert.surveyCertificateStatus);
          return isRejected || isSelectedStatus;
        })
      );
    }
    // Filter surveys by selected countries
    if (selectedCountries.length > 0) {
      surveys = surveys.filter(survey =>
        selectedCountries.includes(survey.countryName)
      );
    }
    // Filter surveys by selected vessels
    if (selectedVessels.length > 0) {
      surveys = surveys.filter(survey =>
        selectedVessels.includes(survey.vesselName)
      );
    }
    // Sort the surveys by creation date
    // surveys = surveys.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));

    // Transform the surveys to include the determined survey status and exclude approved certificates
    let transformedSurveys = surveys.map(survey => {
      const statusMap = {
        New: 0,
        Completed: 0,
        "In-Progress": 0,
        Approved: 0,
        Rejected: 0,
      };

      // Count the certificates by their status
      survey.surveyCertificateList.forEach(cert => {
        if (cert?.comment) {
          statusMap['Rejected']++;
        }
        else if (statusMap.hasOwnProperty(cert.surveyCertificateStatus)) {
          statusMap[cert.surveyCertificateStatus]++;
        }
      });

      // Determine the survey status based on certificate statuses
      let surveyStatus;
      if (statusMap.Approved === survey.surveyCertificateList.length) {
        return null; // Exclude surveys with only "Approved" certificates
      } else if (statusMap.New === survey.surveyCertificateList.length) {
        surveyStatus = "New";
      } else if (statusMap.Completed === survey.surveyCertificateList.length) {
        surveyStatus = "Completed";
      }
      else if (statusMap.Completed + statusMap.Approved === survey.surveyCertificateList.length) {
        surveyStatus = "Completed";
      }
      else if (statusMap.Rejected + statusMap.Approved === survey.surveyCertificateList.length) {
        surveyStatus = "Rejected";
      }
      else {
        surveyStatus = "In-Progress";
      }

      return {
        ...survey,
        surveyStatus, // Add the determined survey status
        surveyCertificateList: survey.surveyCertificateList.filter(cert => cert.surveyCertificateStatus !== "Approved") // Exclude "Approved" certificates
      };
    });

    // Remove null entries (surveys with only "Approved" certificates)
    transformedSurveys = transformedSurveys.filter(survey => survey !== null);

    // Remove duplicates and keep the one entry per survey
    const uniqueSurveysSet = new Set();
    const uniqueSurveys = transformedSurveys.filter(survey => {
      const key = `${survey.surveyId}-${survey.surveyName}-${survey.surveyStatus}`;
      if (uniqueSurveysSet.has(key)) {
        return false;
      }
      uniqueSurveysSet.add(key);
      return true;
    });

    // Sort the unique surveys based on status and creation date
    const sortedSurveys = uniqueSurveys.sort((a, b) => {
      const statusOrderComparison = statusSelectionOrder.indexOf(b.surveyStatus) - statusSelectionOrder.indexOf(a.surveyStatus);
      if (statusOrderComparison !== 0) {
        return statusOrderComparison;
      }
      // Then sort by creation date in descending order
      return new Date(b.updatedOn) - new Date(a.updatedOn);
    });
    setFilteredSurveys(sortedSurveys); // Set the filtered surveys state
  };

  // Get certificates filtered by selected statuses
  const getFilteredCertificates = (certificates) => {
    if (selectedStatuses.length === 0) {
      return certificates;
    }
    return certificates.filter(cert => {
      // Check if "Rejected" is selected
      if (selectedStatuses.includes("Rejected")) {
        // Include "In-Progress" with a non-null comment
        const isRejected = cert.surveyCertificateStatus === "In-Progress" && cert.comment !== null;
        // Include any other selected statuses
        const isSelectedStatus = selectedStatuses.includes(cert.surveyCertificateStatus);
        return isRejected || isSelectedStatus;
      }
      // If "Rejected" is not selected, include any selected statuses
      return selectedStatuses.includes(cert.surveyCertificateStatus);
    });
  };

  // Navigate to view certificates page
  const handleViewCertificates = (survey) => {
    console.log("Sending survey", survey);
    navigate('/viewCertificates', {
      state: {
        survey: survey,
        status: survey.surveyStatus,
        flow: survey.surveyStatus === "Apporved" || survey.surveyStatus === "Completed" ? "SurveyorCompleted" : "In-Progress",
        filterOptions: {
          company: selectedCompanies,
          status: selectedStatuses,
          country: selectedCountries,
          vessel: selectedVessels

        }
      },
    })
  }
  // Format date to DD/MM/YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">

                    <h1 style={{ backgroundColor: '#e5fffd', borderRadius: '10px 10px 0px 0px', padding: '5px' }} ><div className="header-surveyor" >Surveyor Dashboard </div></h1>

                    {
                      loading ? (<LoaderDashboard />) : (

                        <Paper>
                          <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                            <Grid container spacing={2} style={{ display: 'contents' }}>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Companies</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedCompanies}
                                    onChange={handleCompanyChange}
                                    input={<OutlinedInput label="Companies" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {companyNames.sort().map((name) => (
                                      <MenuItem key={name} value={name} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }}>
                                        <Checkbox checked={selectedCompanies.indexOf(name) > -1} />
                                        <ListItemText primary={name} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Flag</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedCountries}
                                    onChange={handleCountryChange}
                                    input={<OutlinedInput label="Countries" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableCountries.sort((a, b) => a.localeCompare(b)).map((name) => (
                                      <MenuItem key={name} value={name} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }}>
                                        <Checkbox checked={selectedCountries.indexOf(name) > -1} />
                                        <ListItemText primary={name} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Vessel Name</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedVessels}
                                    onChange={handleVesselChange}
                                    input={<OutlinedInput label="Vessel Name" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableVessels
                                      .slice()
                                      .filter(i => i)
                                      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                      .map((id) => (
                                        <MenuItem key={id} value={id} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }}>
                                          <Checkbox checked={selectedVessels.includes(id)} />
                                          <ListItemText primary={id} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Status</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedStatuses}
                                    onChange={handleStatusChange}
                                    input={<OutlinedInput label="Status" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {statusOptions.map((status) => (
                                      <MenuItem key={status} value={status} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }}>
                                        <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                        <ListItemText primary={status} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} className="table-container">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell className='table-head-new'>Client Name</TableCell>
                                  <TableCell className='table-head-new'>Vessel Name</TableCell>
                                  {/* <TableCell className='table-head-new' style={{ width: "15%" }}>Survey Number</TableCell> */}
                                  <TableCell className='table-head-new'>Survey Name</TableCell>
                                  <TableCell className='table-head-new'>Status</TableCell>
                                  <TableCell className='table-head-new'>Created By</TableCell>
                                  <TableCell className='table-head-new'>Created Date</TableCell>
                                  <TableCell className='table-head-new'>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredSurveys.map((survey, index) => (
                                  <TableRow key={index}>
                                    <TableCell className="table-cell-survey">{survey.companyName}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.vesselName}</TableCell>
                                    {/* <TableCell className="table-cell-survey">{survey?.surveyNumber}</TableCell> */}
                                    <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.surveyStatus}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.createdBy}</TableCell>
                                    <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                    <TableCell className="table-cell-survey">
                                      <div style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title={survey.surveyStatus === 'Completed' ? "View Certificates" : "View Templates"}>
                                          <IconButton sx={{ padding: '2px' }} onClick={() => handleViewCertificates(survey)}
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>

                      )}


                  </div>

                </div>
              </div>

            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>

  );
}

export default SurveyorDashboard;