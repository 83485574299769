import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../../config";
import Header from '../../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LeftNav from '../../Components/LeftNav/LeftNav';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from "react-router-dom";
import LoaderDashboard from '../../Components/LoaderDashboard';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px !important',
    },
    card: {
        height: '100% !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        textAlign: 'center !important',
        backgroundColor: '#f5f5f5 !important',
        boxShadow: '0 3px 5px rgba(0,0,0,0.2) !important',
        transition: 'transform 0.3s ease-in-out !important',
        '&:hover': {
            transform: 'scale(1.05) !important',
        },
    },
    cardTitle: {
        fontSize: '1.5rem !important',
        marginBottom: '10px !important',
        fontFamily: 'Segoe UI Historic !important'
    },
    cardNumber: {
        fontSize: '2.5rem !important',
        color: '#3f51b5 !important',
        fontFamily: 'Segoe UI Historic !important'
    },
}));



function ReactivateDashboard() {
    const navigate = useNavigate(); // Initialize useNavigate hook for navigation
    // Initialize state variables with default values
    const classes = useStyles();

    // Initialize state for surveys, loading status, and status counts
    const [loading, setLoading] = useState(true);
    const [deletedVesselCount, setDeletedVesselCount] = useState(0); // State for deleted vessels
    const [deletedSurveyCount, setDeletedSurveyCount] = useState(0); // State for deleted vessel

    useEffect(() => {
        fetchDeletedVesselCount(); // Fetch deleted vessel count
        fetchDeletedSurveyCount(); // Fetch deleted vessel count
    }, []);

    // Handle navigation to the survey details view
    const handleClickOn = (item) => {
        if (item == "Vessel") {
            navigate('/cancelledVessels')
        }
        else if (item == "Surveys") {
            navigate('/cancelledSurveys')
        }

    }


    const userRole = localStorage.getItem('role');

    const fetchDeletedVesselCount = async () => {
        try {
            const response = await axios.get(env.backend + "/api/vessel/getVesselsByStatus?status=Deleted");
            setDeletedVesselCount(response.data.length); // Assuming response.data is an array of deleted vessels
        } catch (error) {
            console.error("Error fetching deleted vessels:", error);
        }
        setLoading(false);

    };
    const fetchDeletedSurveyCount = async () => {
        try {
            const response = await axios.get(env.backend + "/api/survey/getDeletedSurveys");
            setDeletedSurveyCount(response.data.length); // Assuming response.data is an array of deleted vessels
        } catch (error) {
            console.error("Error fetching deleted surveys:", error);
        }
        setLoading(false);

    };

    return (
        <div>
            <Header
            />
            {/* <Filtermenu /> */}
            <div className="container-fluid">
                <Tabs className="vertical-tabs">
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <div class="card-listing-section">
                            {/* <h2 class="active-menu ml-3">Listings</h2> */}
                            <div class="ml-3">
                                <div class="right-wrapper" style={{ minHeight: '75vh' }}>
                                    <div style={{ backgroundColor: '#e5fffd', borderRadius: '14px 10px 0px 0px' }}>
                                        <h1 class="heading-dash">Re-Activate Dashboard</h1>
                                    </div>
                                    {
                                        loading ? (<LoaderDashboard />) : (
                                            <div>

                                                <Grid container spacing={3} className={classes.root}>
                                                    {userRole === "Surveyor" ? (
                                                        // Non-clickable Grid
                                                        <>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card}>
                                                                    <CardContent>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Deleted Vessels
                                                                        </Typography>
                                                                        <Typography className={classes.cardNumber} color="textSecondary">
                                                                            {deletedVesselCount}
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card}>
                                                                    <CardContent>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Deleted Surveys
                                                                        </Typography>
                                                                        <Typography className={classes.cardNumber} color="textSecondary">
                                                                            {deletedSurveyCount}
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        // Clickable Grid (default)
                                                        <>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Vessel")}>
                                                                    <CardContent>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Deleted Vessels
                                                                        </Typography>
                                                                        <Typography className={classes.cardNumber} color="textSecondary">
                                                                            {deletedVesselCount}
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Surveys")}>
                                                                    <CardContent>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Deleted Surveys
                                                                        </Typography>
                                                                        <Typography className={classes.cardNumber} color="textSecondary">
                                                                            {deletedSurveyCount}
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>

                                                        </>
                                                    )}
                                                </Grid>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>


        </div >
    );
}

export default ReactivateDashboard