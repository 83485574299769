

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, OutlinedInput, ListItemText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import getCompany from '../../../Pages/Company';
import getVessels from '../../../Pages/Vessels';
import env from '../../../config';
import axios from 'axios';
import Snackbars from '../../Snackbar';



const CreateClientVesselMap = () => {

    const navigate = useNavigate();
    const location = useLocation();

    // State variables for managing component data and UI states
    const [showMessage, setShowMessage] = useState(false);
    const [isUpdate, setIsUpdate] = useState(location.state?.isUpdate);
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState();
    console.log("state", location.state?.data);
    const [selectedCompany, setSelectedCompany] = useState(location.state?.data?.clientId || " ");
    const [selectedCompanyName, setSelectedCompanyName] = useState(location.state?.data?.clientName || " ");
    const [vessels, setVessels] = useState();
    let pData = null;
    if (location.state?.data) {
        pData = JSON.parse(location.state?.data?.vesselInfoJson)
    }
    const [selectedVessels, setSelectedVessels] = useState(pData || []);

    // Snackbar state for error notifications
    const [openRejectSnackbar, setOpenRejectSnackbar] = useState(false);
    // Function to close the rejection snackbar
    const handleCloseRejectSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;  // Prevent closing when clicking away from the snackbar
        }
        setOpenRejectSnackbar(false);  // Close the snackbar
    };

    // Fetch companies and vessels when the component mounts
    useEffect(() => {
        fetchCompanies();
        fetchVessels();
    }, []);

    // Fetch company data from the backend
    const fetchCompanies = async () => {
        try {
            const response = await getCompany("");  // Assuming getCompany() is defined in your API utility
            setCompanies(response);  // Update state with the fetched companies
            // setSelectedCompany(dummy)
            setIsLoading(false);  // Set loading state to false once data is fetched
        } catch (error) {
            // Handle error
            console.log("Error:", error);  // Log errors
            setIsLoading(false);  // Set loading state to false if there's an error
        }
    };

    // Fetch vessel data from the backend
    const fetchVessels = async () => {
        try {
            const response = await getVessels("");  // Assuming getVessels() is defined in your API utility
            setVessels(response);  // Update state with the fetched vessels
            setIsLoading(false);  // Set loading state to false once data is fetched
        } catch (error) {
            // Handle error
            console.log("Error:", error);  // Log errors
            setIsLoading(false);  // Set loading state to false if there's an error
        }
    };

    // Navigate back to the client vessel map page
    const handleCancel = () => {
        navigate('/clientVesselMap');
    };

    // Handle selection of a company from a dropdown or input field
    const handleCompanySelect = async (event) => {
        const value = event.target.value  // Get the selected company ID
        const cName = companies?.filter((company) => company.companyId === value);  // Find the selected company name
        setSelectedCompany(event.target.value);  // Update the selected company ID
        setSelectedCompanyName(cName[0].companyName || " ");  // Update the selected company name
    }

    // Handle selection of vessels from a dropdown or input field
    const handleVesselSelect = async (event) => {
        const value = event.target.value;  // Get the selected vessel(s)
        setSelectedVessels(value);  // Update the selected vessels
    }

    // Handle form submission for creating or updating a client-vessel mapping
    const handleSubmit = async () => {
        if (!isUpdate) {
            // Prepare data for creating a new client-vessel mapping
            const data = {
                clientId: selectedCompany,
                clientName: selectedCompanyName,
                vesselInfoJson: JSON.stringify(selectedVessels),
                createdBy: localStorage.getItem('userFullName'),
                createdOn: new Date()
            }
            console.log("data", data);
            try {
                // Make an API call to the backend to create a new client vessel mapping
                const response = await axios.post(
                    env.backend + "/api/ClientVesselMapping",
                    data
                );

                if (response.status === 201) {
                    // client vessel mapping created successfully
                    console.log("ClientVesselMapping created!", response);
                    navigate('/clientVesselMap', {
                        state: {
                            data: {
                                create: true,  // Indicate that a new entry was created
                            }
                        }
                    });

                } else {
                    // Error occurred while creating the client vessel mapping
                    console.error("Error creating Client Vessel Mapping");
                    setOpenRejectSnackbar(true);

                }
            } catch (error) {
                console.error("Error:", error);
                setOpenRejectSnackbar(true);

                // Additional error handling if needed
            }
        }
        else {
            // Prepare data for updating an existing client-vessel mapping
            const data = {
                clientId: selectedCompany,
                clientName: selectedCompanyName,
                vesselInfoJson: JSON.stringify(selectedVessels),
                updatedBy: localStorage.getItem('userFullName'),
                updatedOn: new Date()
            }
            console.log("data", data);
            try {
                // Make an API call to the backend to update a new client vessel mapping
                const response = await axios.post(
                    env.backend + "/api/ClientVesselMapping",
                    data
                );

                if (response.status === 201) {
                    // client vessel mapping created successfully
                    console.log("ClientVesselMapping update!", response);
                    navigate('/clientVesselMap', {
                        state: {
                            data: {
                                update: true,
                            }
                        }
                    });
                } else {
                    // Error occurred while creating the client vessel mapping
                    console.error("Error updating Client Vessel Mapping");
                    setOpenRejectSnackbar(true);

                    // Additional error handling if needed
                }
            } catch (error) {
                console.error("Error:", error);
                setOpenRejectSnackbar(true);

                // Additional error handling if needed
            }
        }
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 class="titlereg ph-view">{isUpdate ? "Update Client Vessel mapping " : "Client Vessel Mapping"}</h1>
                                            <div>
                                                <form class="formregister">

                                                    {/* <p class="messagereg">Signup now and get full access to our app. </p> */}


                                                    <Select
                                                        sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI' } }}
                                                        value={selectedCompany || " "}
                                                        onChange={handleCompanySelect}
                                                        input={<OutlinedInput label="Companies" />}
                                                        id="clientNameSelect"
                                                        disabled={isUpdate}
                                                    >
                                                        <MenuItem value=" " disabled>
                                                            <ListItemText primary="Select a Client" />
                                                        </MenuItem>
                                                        {companies?.slice()
                                                            .sort((a, b) => (a.companyName?.toLowerCase() || '').localeCompare(b.companyName?.toLowerCase() || ''))
                                                            .map((company) => (
                                                                <MenuItem key={company.companyId} value={company.companyId}>
                                                                    <ListItemText key={company.companyId} primary={company.companyName} />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>


                                                    <Select
                                                        sx={{
                                                            borderRadius: "10px",
                                                            "&.MuiInputBase-input, .MuiOutlinedInput-input": {
                                                                padding: "5px",
                                                                fontFamily: 'Segoe UI'
                                                            }
                                                        }}
                                                        value={selectedVessels || []}
                                                        onChange={handleVesselSelect}
                                                        input={<OutlinedInput label="Vessels" />}
                                                        id="clientVesselSelect"
                                                        multiple
                                                        renderValue={(selected) => selected.map((vessel) => vessel.vesselName).join(', ')}
                                                    > <MenuItem value="[]" disabled>
                                                            <ListItemText primary="Select vessels" />
                                                        </MenuItem>
                                                        {vessels?.slice()
                                                            .sort((a, b) => (a.vesselName?.toLowerCase() || '').localeCompare(b.vesselName?.toLowerCase() || ''))
                                                            .map((vessel) => (
                                                                <MenuItem key={vessel.id} value={vessel}>
                                                                    <Checkbox
                                                                        checked={selectedVessels.some(selectedVessel => selectedVessel.id === vessel.id)}
                                                                    />
                                                                    <ListItemText primary={vessel.vesselName} />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <button type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false}>Submit</button>  */}
                                        {/* {showMessage && <div><b><green>New client vessel mapping created successfully!!!</green></b></div>} */}
                                        <div class="btn-container-multiple">
                                            <button class="modal-submit-btn" onClick={handleSubmit}  ><b> {isUpdate ? "Update" : "Save"}</b></button>

                                            <button style={{ marginRight: "23px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
                                        </div>
                                        {/* <span>
                                        <Link to="/Signin">Sign In</Link>
                                    </span> */}
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
            <Snackbars
                openSnackbar={openRejectSnackbar}
                handleCloseSnackbar={handleCloseRejectSnackbar}
                type="error"
                message={"Vessel Mapping failed"}
            />
        </div>


    )
}

export default CreateClientVesselMap
