import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import getVessels from "./Vessels";
import getCountry from "./Country";
import getCompany from "./Company";
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import Loader from "../Components/Loader";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import handleToken from "../Components/TokenHandler";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
  OutlinedInput, Button,

} from '@mui/material';
import '../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LeftNav from "../Components/LeftNav/LeftNav";
import Header from "../Header/Header";
import Snackbars from "../Components/Snackbar";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Box } from '@mui/material';



function TemplateNamesSection({ templateNames, selectedTemplateNames, handleLeftTemplateNamesChange, handleRightTemplateNamesChange }) {

  return (
    <div >
      <span className="required-star" style={{ top: '5%' }}>*</span>
      <label className="label" htmlFor="templateNames">Templates</label>
      <div className="template-names-container">
        <div className="template-names-column left-column">
          <div className="temp">Available Templates</div>
          {templateNames
            .filter(template => template.templateName)
            .sort((a, b) => a.templateName.localeCompare(b.templateName, undefined, { sensitivity: 'base' }))
            .map((templateName, index) => (
              <div
                key={templateName.templateId}
                className="template-name-item"
                onClick={() => handleLeftTemplateNamesChange(templateName)}
              >
                {templateName.templateName}
              </div>
            ))}
        </div>

        <SwapHorizontalCircleIcon />
        <div className="template-names-column right-column">
          <div className="temp">Selected Templates</div>
          {selectedTemplateNames && selectedTemplateNames.slice().reverse().map((selectedName, index) => (
            <div
              key={index}
              className="selected-name-item"
              onClick={() => {
                handleRightTemplateNamesChange(selectedName);
                console.log(selectedName.templateName);
              }}
            >
              {selectedName.templateName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { TemplateNamesSection };


function CreateSurvey() {
  const navigate = useNavigate();
  const location = useLocation();

  // State variables for managing form inputs and options
  const [name, setName] = useState("");
  const [surveynumber, setSurveyNo] = useState("");
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [defaultTemplates, setDefaultTemplates] = useState([])
  const [nonHiddenTemplates, setNonHiddenTemplates] = useState([]);
  const [surveyorNames, setSurveyorNames] = useState([]);
  const [selectedTemplateNames, setSelectedTemplateNames] = useState([]);
  const [selectedSurveyTemplateNames, setSelectedSurveyTemplateNames] = useState([]);
  const [completionDate, setCompletionDate] = useState('');
  const [validUntilDate, setValidUntilDate] = useState('');
  const [issuePlace, setIssuePlace] = useState('');
  const [issueDate, setIssueDate] = useState('');

  // State variables for managing surveys and loading state
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);

  // State variables for managing country addition and file selection
  const [showAddCountry, setShowAddCountry] = useState(false);
  const [hideAddCountry, setHideAddCountry] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [newCountry, setNewCountry] = useState("");

  // State variables for submission and survey selection
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(true);
  const exSurvey = { label: 'Existing Surveys', value: { surveyName: 'All Templates' } }
  const [selectedSurvey, setSelectedSurvey] = useState(exSurvey);
  const [newSelectedSurvey, setNewSelectedSurvey] = useState('');
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedSurveyorNames, setSelectedSurveyorNames] = useState([]);

  const goback = location.state?.from?.pathname || "/";
  const surveyordb = location.state?.from?.pathname || "/survey-dashboard";

  // Transform companies and countries into options for select elements
  const company = companies.map((company) => ({
    value: company,
    label: company.companyName,
  }));
  const country = countries.map((country) => ({
    value: country,
    label: country.countryName,
  }));
  const { auth } = useContext(AuthContext);

  const [selectOption, setSelectOption] = useState(null);

  // State variables for survey details
  const [surveyCompany, setSurveyCompany] = useState("DAKAR");
  const [vessel, setVessel] = useState([]);
  const [surveySelected, setSurveySelected] = useState([exSurvey]);
  const [selectedVessel, setSelectedVessel] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [isClonedSurvey, setIsClonedSurvey] = useState(false);
  const [hideTemplateOptions, setHideTemplateOptions] = useState(false);
  const [load, setLoad] = useState(false);


  //const [selectedCoutry, setSelectedCountry] = useState("");
  const [openRejectSnackbar, setOpenRejectSnackbar] = useState(false);

  // Function to close the reject snackbar notification
  const handleCloseRejectSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenRejectSnackbar(false);
  };
  useEffect(() => {
    // Fetch options, companies, countries, and surveys
    fetchOptions();
    fetchCompanies();
    fetchCountries();
    fetchSurveys();
    // handleToken();
  }, []);

  const fetchSurveys = async () => {
    try {
      // Make a GET request to fetch survey data
      const response = await axios.get(ENV.backend + "/api/survey");
      // Convert the response data to an array and log it for debugging
      const surveysData = Object.values(response.data);
      console.log("Fetched Surveys:", surveysData);
      setSurveys(surveysData);
      setLoading(false);
    } catch (error) {
      // Handle errors by logging and updating state
      console.error("Error fetching surveys:", error);
      setLoading(true);
      // alert("Error fetching surveys");
      localStorage.clear()
      window.location.replace('/')
    }
  };

  const fetchOptions = async () => {
    try {
      // Fetch template names from the API
      const templateNamesResponse = await axios.get(
        ENV.backend + "/api/template"
      );
      // Log the access token for debugging
      console.log("bearer " + localStorage.getItem('accessToken'));
      // Fetch surveyor names with authorization header
      const surveyorNamesResponse = await axios.get(
        ENV.identityApi + "/api/identity/GetUsersByRole?role=surveyor",
        { headers: { Authorization: "bearer " + localStorage.getItem('accessToken') } }
      ).catch(e => {
        // alert('Error! Failed to obtain surveyor data. Please logout and try again.')
        localStorage.clear()
        window.location.replace('/')
      });

      // Convert the response data to arrays
      const allowedTemplateNames = [
        "NCS-CL-PR-NEW",
        "NCS-BC-PR-NEW",
        "NCS-CRT-PR-NEW",
        "NCS-IA-PR-NEW",
        "NCS-IBC-PR-NEW",
        "DEMOCC"
      ];
  
      let templates = Object.values(templateNamesResponse.data);
  
      // Filter the templates to include only the allowed names
      templates = templates.filter(template => 
        allowedTemplateNames.includes(template.templateName)
      );
      const surveyors = Object.values(surveyorNamesResponse.data);

      console.log("templatecheck", templateNamesResponse);
      templates.map((templateName, index) =>
        console.log(templateName.templateId)
      );

      surveyors.map((surveyorName, index) => console.log(surveyorName.id));

      // Update state with fetched template names and surveyor names
      setTemplateNames(templates);
      setDefaultTemplates(templates);
      console.log("templates", templates);
      setSurveyorNames(surveyors);

      //setSurveyorNames(surveyorNamesResponse.data);
    } catch (error) {
      // Handle errors by logging them
      console.error("Error fetching options:", error);
    }
  };
  // const fetchOptions = async () => {
  //   try {
  //     const templateNamesResponse = await axios.get(
  //       ENV.backend + "/api/template"
  //     );
  //     console.log("bearer " + localStorage.getItem('accessToken'));

  //     const surveyorNamesResponse = await axios.get(
  //       ENV.identityApi + "/api/identity/GetUsersByRole?role=surveyor",
  //       { headers: { Authorization: "bearer " + localStorage.getItem('accessToken') } }
  //     ).catch(e => {
  //       localStorage.clear();
  //       window.location.replace('/');
  //     });

  //     const allowedTemplateNames = [
  //       "NCS-CL-PR",
  //       "NCS-BC-PR",
  //       "NCS-CRT-PR",
  //       "NCS-IA-PR",
  //       "NCS-IBC-PR"
  //     ];

  //     let templates = Object.values(templateNamesResponse.data);

  //     // Filter the templates to include only the allowed names
  //     templates = templates.filter(template => 
  //       allowedTemplateNames.includes(template.templateName)
  //     );

  //     const surveyors = Object.values(surveyorNamesResponse.data);

  //     console.log("Filtered Templates:", templates);
  //     templates.map((templateName) =>
  //       console.log(templateName.templateId)
  //     );

  //     surveyors.map((surveyorName) => console.log(surveyorName.id));

  //     setTemplateNames(templates);
  //     setDefaultTemplates(templates);
  //     console.log("templates", templates);
  //     setSurveyorNames(surveyors);

  //   } catch (error) {
  //     console.error("Error fetching options:", error);
  //   }
  // };



  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
    /*if (event.target.files[0]) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
    

      fetch(ENV.backend+'/api/upload', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (response.ok) {
            console.log('File uploaded successfully');
          } else {
            console.error('Failed to upload file');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }*/
  };



  const fetchCompanies = async () => {
    try {
      // Fetch company data and log the response
      const response = await getCompany("");
      console.log(response);
      setCompanies(response);

      setIsLoading(false);// Set loading state to false as data is successfully fetched
    } catch (error) {
      // Handle error
      console.log("Error:", error);// Handle any errors that occur during fetching
      setIsLoading(false);
    }
  };

  // Function to fetch country data from an API
  const fetchCountries = async () => {
    try {
      // Fetch country data and log the response
      const response = await getCountry("");
      console.log(response);
      setCountries(response);
    } catch (error) {
      // Handle error
      console.log("Error:", error);
    }
  };

  // Handler for changes in the name input field
  const handleNameChange = (event) => {
    // Update the state with the new name value from the input field
    setName(event.target.value);
  };

  // Handler for changes in the survey number input field
  const handleNumberChange = (event) => {
    // Update the state with the new survey number value from the input field
    setSurveyNo(event.target.value);
  };

  // Handler for changes in the selected vessel
  const handleValueChange = (event) => {
    console.log("Change in vessel", event.target.value);

    // Get the selected vessel value
    const selectedVessel = event.target.value;
    setSelectedVessel(selectedVessel);
    setSelectedSurvey('');

    // Filter surveys based on selected company and vessel
    const s = surveys
      .filter((survey) => selectedCompany && survey.companyName === selectedCompany.companyName)
      .filter((survey) => survey.vesselId === selectedVessel.value.id)
      .map((survey) => ({
        value: survey,
        label: survey.surveyName,
      }));

    setSurveySelected([exSurvey, ...s]);

    // Automatically select the flag country based on the selected vessel's flag
    const selectedFlag = selectedVessel.value.flag; // Assuming `flag` is used to match `countryName`
    const matchingCountry = sortedCountries.find(country => country.label === selectedFlag);
    // Set the selected country based on the flag, or clear the selection if no match
    if (matchingCountry) {
      setSelectedCountry(matchingCountry.value);
      console.log("Selected country based on flag:", matchingCountry.label);
    } else {
      setSelectedCountry(null);
      console.log("No matching country found for flag:", selectedFlag);
    }
  };

  // Handler for survey selection changes
  const handleSurveySelection = (event) => {
    console.log('event passing is .', event.target.value)
    console.log("selectedSurvey is", selectedSurvey);
    console.log("Default templates", defaultTemplates);
    console.log("left template names", templateNames);
    // Check if 'All Templates' is selected
    if (event.target.value.label === 'All Templates') {
      setSelectedSurvey(event.target.value);
      setSelectedTemplateNames([]);
      setNonHiddenTemplates(defaultTemplates);
    }
    else {
      // Get the selected survey ID and find the corresponding survey
      const selectedSurveyId = event.target.value?.value?.surveyId;
      const optionSelectedSurvey = surveys.find((survey) => survey.surveyId === selectedSurveyId);

      setSelectedSurvey(event.target.value);
      setNonHiddenTemplates([]);
      console.log('Selected Survey:', optionSelectedSurvey);

      if (optionSelectedSurvey) {
        // Filter approved certificates from the selected survey
        const approvedCertificates = optionSelectedSurvey.surveyCertificateList?.filter(
          (cert) => cert.surveyCertificateStatus === "Approved"
        ) || [];

        // Map approved certificates to template names and IDs
        const newlySelectedTemplateNames = approvedCertificates.map(
          (certificate) => ({ templateName: certificate.certificateName, templateId: certificate.templateId })
        );

        // Filter out templates that are in the selected survey
        const updatedLeftTemplates = templateNames.filter((template) => {
          return !newlySelectedTemplateNames.some(
            (selectedTemplate) => selectedTemplate.templateName === template.templateName
          );
        });

        // Update state with new template names and indicate a cloned survey
        setTemplateNames(updatedLeftTemplates);
        console.log('Selected Template Names:', newlySelectedTemplateNames);
        setSelectedTemplateNames(newlySelectedTemplateNames);
        setIsClonedSurvey(true);
      }

      // Determine whether to hide template options based on selected survey
      const hideTemplateOptions = optionSelectedSurvey ? true : false;
      setHideTemplateOptions(hideTemplateOptions);
    }
  };

  // Handler for when a company is selected
  const handleCompanySelect = async (event) => {
    const value = event.target.value
    console.log(value);

    // Update state with selected company details
    setSelectOption(value);
    setSelectedSurvey('');
    setSurveyCompany("DAKAR");
    setSelectedVessel('');
    console.log(surveyCompany);
    setSelectedCompany(event.target.value);
    setSelectedCompanyName(value.companyName);

    try {
      // Fetch vessels for the selected company
      const response = await getVessels("", value);
      console.log(response);

      // Filter and format the vessels based on company ID
      const v = response
        .filter((v) => v.companyId === value.companyId)
        .map((v) => ({
          value: v,
          label: v.vesselName,
        }));
      console.log("my new vessel list", v)
      // Update state with the filtered vessels
      setVessel(v)

      // Filter and format the surveys based on selected company and vessel
      const s = surveys
        .filter((survey) => survey.companyName === value.companyName)
        .filter((survey) => selectedVessel && survey.vesselId === selectedVessel.value.companyId)
        .map((survey) => ({
          value: survey,
          label: survey.surveyName,
        }));
      console.log("my new survey list", s)
      // setSurveySelected(s);

      // Update state with filtered surveys and show add country option
      setSurveySelected([exSurvey, ...s]);
      setShowAddCountry(true);
    } catch (error) {
      // Handle error
      console.log("Error:", error);
    }
  };

  // Handler for adding a template to the selected list
  const handleLeftTemplateNamesChange = (templateName) => {
    // Check if the template is not already selected
    if (!selectedTemplateNames.includes(templateName)) {
      // Add the template to the selected list
      setSelectedTemplateNames([...selectedTemplateNames, templateName]);


      // Remove the template from the available list
      const updatedLeftTemplates = templateNames.filter(
        (name) => name !== templateName
      );
      setTemplateNames(updatedLeftTemplates);

      // Remove the template from non-hidden list if it exists
      const updatedLeftHiddenTemplates = nonHiddenTemplates.filter(
        (name) => name !== templateName
      );
      setNonHiddenTemplates(updatedLeftHiddenTemplates);
    }
    // Optionally, set hideTemplateOptions to false (commented out here)
    // setHideTemplateOptions(false);
  };

  // Handler for removing a template from the selected list
  const handleRightTemplateNamesChange = (templateName) => {
    // Remove the template from the selected list
    const updatedSelectedTemplates = selectedTemplateNames.filter(
      (name) => name !== templateName
    );
    setSelectedTemplateNames(updatedSelectedTemplates);
    // Update the list of templates based on the hideTemplateOptions state
    console.log("hidden condition", hideTemplateOptions);
    if (hideTemplateOptions) {
      // If options are hidden, add the template back to non-hidden list
      setNonHiddenTemplates([...nonHiddenTemplates, templateName])
      console.log("ahvhvas", nonHiddenTemplates);
    } else {
      // Otherwise, add the template back to the available list
      setTemplateNames([...templateNames, templateName]);
    }
  };

  // Handler for adding a surveyor to the selected list
  const handleLeftSurveyorNamesChange = (surveyor) => {
    // Check if the surveyor is not already selected    
    if (!selectedSurveyorNames.some(item => `${item.firstName} ${item.lastName}` === `${surveyor.firstName} ${surveyor.lastName}`)) {
      // Add the surveyor to the selected list
      setSelectedSurveyorNames([...selectedSurveyorNames, surveyor]);

      // Remove the surveyor from the available list
      const updatedLeftSurveyors = surveyorNames.filter(
        (name) => `${name.firstName} ${name.lastName}` !== `${surveyor.firstName} ${surveyor.lastName}`
      );
      setSurveyorNames(updatedLeftSurveyors);
    }

  };

  // Handler for removing a surveyor from the selected list
  const handleRightSurveyorNamesChange = (surveyor) => {
    // Remove the surveyor from the selected list
    const updatedSelectedSurveyors = selectedSurveyorNames.filter(
      (name) => `${name.firstName} ${name.lastName}` !== `${surveyor.firstName} ${surveyor.lastName}`
    );
    setSelectedSurveyorNames(updatedSelectedSurveyors);
    // Add the surveyor back to the available list
    console.log("hidden condition", hideTemplateOptions);
    setSurveyorNames([...surveyorNames, surveyor]);
  };

  // Handler for changing the list of selected surveyors
  const handleSurveyorNamesChange = (event) => {
    // Get the clicked option value
    const clickedOption = event.target.value;

    // Check if the clicked option is already in the selected list
    const isSelected = selectedSurveyorNames.includes(clickedOption);

    if (isSelected) {
      // If the option is already selected, remove it from the list
      const updatedSelection = selectedSurveyorNames.filter(option => option !== clickedOption);
      setSelectedSurveyorNames(updatedSelection);
    } else {
      // If the option is not selected, add it to the list
      setSelectedSurveyorNames(prevSelection => [...prevSelection, clickedOption]);
    }
  };
  const handleCompletionDateChange = (newValue) => {
    setCompletionDate(newValue);
  };

  const handleValidUntilDateChange = (newValue) => {
    setValidUntilDate(newValue);
  };

  const handleIssueDateChange = (newValue) => {
    setIssueDate(newValue);
  };

  const handleIssuePlaceChange = (e) => {
    setIssuePlace(e.target.value);
  };
  const convertToNextDayISO = (dateString) => {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Convert the date back to ISO format
    return date.toISOString();
  };


  // Handler for form submission

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setSubmitted(false); // Set the form as not submitted

    // Log the values to be submitted
    console.log("Submitted Name:", name);
    console.log("Submitted number:", surveynumber);
    console.log("Selected Template Names:", selectedTemplateNames);
    console.log("Selected Surveyor Names:", selectedSurveyorNames);

    // Validate required fields
    if (!name || !selectedCompany || !selectedVessel || !selectedCountry) {
      alert("Please fill in all required fields.");
      return;
    }
    if (!selectedTemplateNames || selectedTemplateNames.length === 0) {
      alert("Please select at least one Template.");
      return;
    }
    if (!selectedSurveyorNames || selectedSurveyorNames.length === 0) {
      alert("Please select at least one Surveyor.");
      return;
    }

    // Log selected country and vessel
    console.log(selectedCountry);
    console.log(selectedVessel);
    const vesselId = parseInt(selectedVessel.value.id);

    console.log("vessel id ", selectedVessel);

    // Create array of selected template names with necessary properties
    const selectedTemplateNamesArray = selectedTemplateNames
      .filter((value) => value.templateName !== null) // Filter out null template names
      .map((value) => ({
        templateId: parseInt(value.templateId), // Convert templateId to integer
        certificateName: value.templateName,
        surveyCertificateStatus: "New",
        createdOn: new Date(),
        createdBy: localStorage.getItem('userFullName'),
      }));

    // Create array of selected surveyors with necessary properties
    const selectedSurveyorNamesArray = selectedSurveyorNames.map((value) => ({
      userId: `${value.firstName} ${value.lastName}`,
      createdOn: new Date(),
      createdBy: localStorage.getItem('userFullName'),

    }));

    console.log(selectedTemplateNamesArray);
    console.log(selectedSurveyorNamesArray);
    setLoad(true);
    let submittedBy = localStorage.getItem('userFullName');

    // Create new survey object
    const newSurvey = {
      surveyName: name,
      surveyNumber: surveynumber,
      surveyCreatedBy: submittedBy,
      companyId: selectedCompany.companyId,
      companyName: selectedCompany.companyName,
      surveyorCompanyName: surveyCompany,
      vesselId: vesselId,
      vesselName: selectedVessel?.value?.vesselName,
      countryId: selectedCountry.id,
      countryName: selectedCountry.countryName,
      flag: selectedCountry.countryName,
      surveyCertificateList: selectedTemplateNamesArray,
      surveyorsList: selectedSurveyorNamesArray,
      surveyStatus: "New",
      surveyCreatedOn: new Date(),
      surveyEndDate: new Date(),
      createdBy: submittedBy,
      createdOn: new Date(),
      updatedBy: submittedBy,
      updatedOn: new Date(),
      isCloned: isClonedSurvey,
      clonedSurveyId: selectedSurvey.surveyId,
      clonedSurveyName: selectedSurvey.surveyName,
      completionDate: completionDate,
      validUntilDate: validUntilDate,
      issueDate: issueDate,
      issuePlace,
    };

    console.log("new survey ", newSurvey);
    console.log("selected survey", selectedSurvey.surveyName);

    setIsLoading(true)
    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(ENV.backend + "/api/survey", newSurvey);

      if (response.status === 201) {
        setIsLoading(false)
        console.log("Survey created!"); // User created successfully
        // Navigate to survey dashboard with additional state
        navigate(surveyordb, {
          state: {
            filterOptions: location.state?.filterOptions,
            create: true,
          }
        });
        // Reset form fields and selections
        setName("");
        setSurveyNo("");
        setSelectedTemplateNames([]);
        setSelectedSurveyorNames([]);
        // handleClose();
        // Additional actions if needed
      } else {
        // Error occurred while creating the user
        console.error("Error creating Survey");
        setOpenRejectSnackbar(true);
        // Additional error handling if needed
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error:", error);
      setOpenRejectSnackbar(true);
      // Additional error handling if needed

    } finally {
      setLoad(false);
    }
    // Reset input fields and selections

  };

  // Handler for selecting a country
  const handleCountrySelect = (event) => {
    console.log("country change", event.target.value)
    setSelectedCountry(event.target.value); // Update selected country
  };

  // Show add country modal
  const handleAddCountry = () => {
    setShowAddCountry(true);
    setHideAddCountry(true);
  };

  // Navigate to survey dashboard
  const handleClose = () => {
    navigate('/survey-dashboard', {
      state: {
        filterOptions: location.state?.filterOptions
      }
    });
  };

  // Close add country modal
  const closeAddCountry = () => {
    setShowAddCountry(true);
    setHideAddCountry(false);
  }

  // Sort countries alphabetically by label
  const sortedCountries = [...country].sort((a, b) => a.label.localeCompare(b.label));

  // Handler for submitting new country
  const handleAddCountrySubmit = async () => {
    // Find the maximum ID from existing countries and increment it for the new country
    const maxId = country.reduce((max, country) => {
      return country.value.id > max ? country.value.id : max;
    }, 0);
    const newId = maxId + 1;

    const newCountryObject = {
      companyId: newId,
      countryName: newCountry,

    };

    console.log(newCountryObject);

    try {
      // Make an API call to the backend to create a new country
      const response = await axios.post(
        ENV.backend + "/api/country",
        newCountryObject
      );

      if (response.status === 201) {
        // User created successfully
        console.log("Country created!");
        // Additional actions if needed
      } else {
        // Error occurred while creating the user
        console.error("Error creating Country");
        // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

    // Add the new company to the state
    //setCountry([...country, newCountryObject]);

    fetchCountries();
    setNewCountry("");
    // setShowAddCountry(false);
    // setHideAddCountry(true);
    closeAddCountry();
  };

  return (
    <div>

      <header>
        <Header />
      </header>
      <div className="container-fluid container-fluid-ex">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>

            <main>
              <div className="container-fluid-buyer">
                <div className="card-listing-section">
                  <div className="right-wrapper right-wrapper-ex">
                    {loading ? (
                      <Loader />
                    ) : (

                      <div>

                        <h2 className="titlereg ph-view" style={{ marginTop: "10x", marginBottom: "16px" }}><b>Create Survey</b></h2>
                        <div className="overflow-createsurvey">
                          <form className="formregister" style={{ marginTop: "-4px" }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                              {/* Left Column */}
                              <div style={{ flex: '1 1 48%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                  <label>
                                    <div className="input-container">
                                      <span className="required-star">*</span>

                                      <TextField
                                        label="Survey Name"
                                        value={name}
                                        onChange={handleNameChange}

                                        fullWidth // Ensures full width
                                        sx={{
                                          '.MuiOutlinedInput-root': { borderRadius: '10px' },
                                          '.MuiInputBase-input': { fontFamily: 'Segoe UI Historic' },
                                          '.MuiFormLabel-root': {
                                            fontFamily: 'Segoe UI Historic',
                                            marginTop: '-6px',
                                            '&.MuiInputLabel-shrink': { marginTop: '0px' }
                                          }
                                        }}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </label>
                                  <label>
                                    <TextField
                                      label="Survey Number"
                                      value={surveynumber}
                                      onChange={handleNumberChange}

                                      fullWidth // Ensures full width
                                      sx={{
                                        '.MuiOutlinedInput-root': { borderRadius: '10px' },
                                        '.MuiInputBase-input': { fontFamily: 'Segoe UI Historic' },
                                        '.MuiFormLabel-root': {
                                          fontFamily: 'Segoe UI Historic',
                                          marginTop: '-6px',
                                          '&.MuiInputLabel-shrink': { marginTop: '0px' }
                                        }
                                      }}
                                      autoComplete="off"
                                    />
                                  </label>


                                  <FormControl fullWidth >
                                    <InputLabel
                                      htmlFor="roles"
                                      id="clientName"
                                      sx={{
                                        "&.MuiInputLabel-root": {
                                          marginTop: '-11px',
                                          fontFamily: 'Segoe UI Historic'
                                        },
                                        "&.MuiInputLabel-shrink": {
                                          marginTop: '0px'
                                        }
                                      }}
                                    >
                                      Client Name
                                    </InputLabel>
                                    <span className="required-star">*</span>
                                    <Select
                                      sx={{
                                        height: "40px",
                                        borderRadius: "10px",
                                        "&.MuiInputBase-input, .MuiOutlinedInput-input": {
                                          height: "40px",
                                          padding: "5px",
                                          fontFamily: 'Segoe UI Historic',
                                        }
                                      }}
                                      value={selectedCompany}
                                      onChange={handleCompanySelect}
                                      input={<OutlinedInput label="Companies" />}
                                      id="clientNameSelect"
                                    >
                                      {companies
                                        .slice()
                                        .sort((a, b) => a.companyName?.toLowerCase().localeCompare(b.companyName?.toLowerCase()))
                                        .map((company) => (
                                          <MenuItem key={company?.companyName} value={company}>
                                            <ListItemText primary={company?.companyName} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl fullWidth >
                                    <InputLabel
                                      htmlFor="vessel"
                                      id="vesselName"
                                      sx={{
                                        "&.MuiInputLabel-root": {
                                          marginTop: '-11px',
                                          fontFamily: 'Segoe UI Historic'
                                        },
                                        "&.MuiInputLabel-shrink": {
                                          marginTop: '0px'
                                        }
                                      }}
                                    >
                                      Vessel Name
                                    </InputLabel>
                                    <span className="required-star">*</span>
                                    <Select
                                      value={selectedVessel}
                                      sx={{
                                        height: "40px",
                                        borderRadius: "10px",
                                        "&.MuiInputBase-input, .MuiOutlinedInput-input": {
                                          height: "40px",
                                          padding: "5px",
                                          fontFamily: 'Segoe UI Historic',
                                        }
                                      }}
                                      id="vesselNameSelect"
                                      labelId="vesselName"
                                      label="Vessel Name"
                                      onChange={handleValueChange}
                                    >
                                      <MenuItem value='' disabled selected>Select Vessel</MenuItem>
                                      {vessel.map((ves, index) => (
                                        <MenuItem key={index} value={ves}>
                                          {ves.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                </Box>
                              </div>


                              {/* Right Column */}
                              <div style={{ flex: '1 1 48%', marginBlock: '6px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                  <TextField
                                    label="Completion Date"
                                    type="Date"
                                    value={completionDate}
                                    onChange={(e) => setCompletionDate(e.target.value)}
                                    sx={{
                                      '.MuiOutlinedInput-root': { borderRadius: '10px !important' },
                                      '.MuiInputBase-input': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        padding: '8px !important',
                                        textTransform: 'uppercase !important',
                                      },
                                      '.MuiFormLabel-root': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        marginTop: '-6px !important',
                                        '&.MuiInputLabel-shrink': { marginTop: '0px !important' }
                                      }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                  />


                                  <TextField
                                    label="Valid Until Date"
                                    type="date"
                                    value={validUntilDate}
                                    onChange={(e) => setValidUntilDate(e.target.value)}
                                    sx={{
                                      '.MuiOutlinedInput-root': { borderRadius: '10px !important' },
                                      '.MuiInputBase-input': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        padding: '8px !important',
                                        textTransform: 'uppercase !important',
                                      },
                                      '.MuiFormLabel-root': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        marginTop: '-6px !important',
                                        '&.MuiInputLabel-shrink': { marginTop: '0px !important' }
                                      }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                  />

                                  <TextField
                                    label="Issue At"
                                    placeholder="Certificate Issued Place"
                                    value={issuePlace}
                                    onChange={handleIssuePlaceChange}
                                    sx={{

                                      '.MuiOutlinedInput-root': { borderRadius: '10px !important' },
                                      '.MuiInputBase-input': { fontFamily: 'Segoe UI Historic !important' },
                                      '.MuiFormLabel-root': {
                                        fontFamily: 'Segoe UI Historic',
                                        marginTop: '-6px !important',
                                        '&.MuiInputLabel-shrink': { marginTop: '0px !important' }
                                      }
                                    }}
                                  />

                                  <TextField
                                    label="Date Of Issue"
                                    type="date"
                                    value={issueDate}
                                    onChange={(e) => setIssueDate(e.target.value)}
                                    sx={{
                                      '.MuiOutlinedInput-root': { borderRadius: '10px !important' },
                                      '.MuiInputBase-input': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        padding: '8px !important',
                                        textTransform: 'uppercase !important',
                                      },
                                      '.MuiFormLabel-root': {
                                        fontFamily: 'Segoe UI Historic !important',
                                        marginTop: '-6px !important',
                                        '&.MuiInputLabel-shrink': { marginTop: '0px !important' }
                                      }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Box>
                              </div>
                            </div>
                            <div>
                              <div className="select-container">


                                <FormControl fullWidth >
                                  <InputLabel htmlFor="roles" id="flag-country" sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Flag Country</InputLabel>
                                  <span className="required-star">*</span>
                                  <Select value={selectedCountry || ''} sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id="flag-countrySelect" label="Flag Country"
                                    onChange={handleCountrySelect}>

                                    {sortedCountries.map((ves, index) => (
                                      <MenuItem key={index} value={ves.value}>
                                        {ves.label}
                                      </MenuItem>
                                    ))}

                                  </Select>
                                </FormControl>


                                {showAddCountry && (
                                  <div className="button-container">
                                    <AddBoxIcon sx={{ color: "green", width: '60px', height: '35px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddCountry}>
                                    </AddBoxIcon>
                                  </div>
                                )}

                              </div>

                              {hideAddCountry && (
                                <div id="id02" className="submodal">
                                  <div className="modal-content animate">
                                    <div className="submodal-container">
                                      {/* <button className="cross-button" onClick={closeAddCountry}>X</button> */}
                                      <input
                                        type="text"
                                        value={newCountry}
                                        onChange={(e) => setNewCountry(e.target.value)}
                                        placeholder="Country Name"
                                      />

                                      <div>

                                        <input class="modal-choose-btn" type="file" onChange={handleFileUpload} >
                                        </input>
                                      </div>
                                      <div class="btn-container-multiple">
                                        <button class="modal-close-btn" onClick={closeAddCountry}>Close</button>
                                        <button class="modal-submit-btn" onClick={handleAddCountrySubmit}>Save</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>


                            <div>
                              <TemplateNamesSection
                                templateNames={hideTemplateOptions ? nonHiddenTemplates : templateNames}
                                selectedTemplateNames={selectedTemplateNames}
                                handleLeftTemplateNamesChange={handleLeftTemplateNamesChange}
                                handleRightTemplateNamesChange={handleRightTemplateNamesChange}
                              />

                            </div>

                            <div>

                              <label className="label" htmlFor="templateNames"><span className="required-surveyor">*</span>Surveyors</label>

                              <div className="template-names-container">
                                <div className="template-names-column left-column">
                                  <div className="temp">Available Surveyors</div>
                                  {surveyorNames
                                    .slice()
                                    .sort((a, b) => {
                                      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                                      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                                      return nameA.localeCompare(nameB);
                                    })
                                    .map((surveyorName) => (
                                      <div
                                        key={surveyorName.id}
                                        className="template-name-item"
                                        onClick={() => handleLeftSurveyorNamesChange(surveyorName)}
                                      >
                                        {surveyorName.firstName + ' ' + surveyorName.lastName}
                                      </div>
                                    ))}
                                </div>


                                <div className="template-names-column right-column">
                                  <div className="temp">Selected Surveyors</div>
                                  {selectedSurveyorNames && selectedSurveyorNames.slice().reverse().map((surveyorName, index) => (
                                    <div
                                      key={index}
                                      className="selected-name-item"
                                      onClick={() => {
                                        handleRightSurveyorNamesChange(surveyorName);
                                        console.log(surveyorName.surveyorName);
                                      }}
                                    >
                                      {surveyorName.firstName + ' ' + surveyorName.lastName}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>


                          </form>
                        </div>
                        <div class="btn-container-multiple">
                          <button className="modal-close-btn" onClick={handleClose}>
                            <b>Close</b>
                          </button>
                          {load && <Loader />}
                          <button className="modal-submit-btn" onClick={handleSubmit}>
                            <b> Save</b>
                          </button>


                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
      <Snackbars
        openSnackbar={openRejectSnackbar}
        handleCloseSnackbar={handleCloseRejectSnackbar}
        type="error"
        message={"Survey Creation failed"}
      />
    </div>
  );
}

export default CreateSurvey;