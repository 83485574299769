import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button, DialogContentText, DialogTitle, Dialog, DialogContent, DialogActions, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './CancelledVessels.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import axios from "axios";
import env from '../../../config';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const CancelledVessels = () => {
    // State variables
    const [vesselList, setVesselList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
    const [selectedVessel, setSelectedVessel] = useState(null);


    // Fetch vessels data when the component mounts
    useEffect(() => {
        fetchSurveys();
        // handleToken();
    }, []);

    // Fetch vessels from the backend
    const fetchSurveys = async () => {
        try {
            const response = await axios.get(env.backend + "/api/vessel/getVesselsByStatus?status=Deleted");
            const vessels = Object.values(response.data);
            const sortedVessels = vessels;
            setVesselList(sortedVessels);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching surveys:", error);
            setLoading(true);
            // alert("error fetching surveys")
            //   localStorage.clear()
            //   window.location.replace('/')
        }
    };

    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState(location.state?.search || '');
    const [checked, setChecked] = useState(false);

    const handleChangeSelect = (event) => {
      setChecked(event.target.checked);
    };
    // Handle changes in the search input field
    const handleSearchChange = (event) => {
        setSearch(event.target.value); // Update search state
    };


    // Navigate to the vessel details page
    const handleViewVessel = (vessel) => {
        navigate('/viewvessel', { state: { vessel, search: search, path: '/cancelledVessels' } }); // Pass selected vessel as state
    };



    // Open the reactivate dialog for a selected vessel
    const handleCancelledVessels = (vessel) => {
        // setSelectedVessel(vessel);
        // setOpenReactivateDialog(true);
        navigate('/reactivatevessel', { state: { vesselData: vessel, isupdate: true, search: search } });
    };

    // Close the reactivate dialog
    const handleCloseReactivateDialog = () => {
        setOpenReactivateDialog(false);  // Hide reactivate dialog
        setComment('');  // Clear comment field
    };

    // Confirm and proceed with vessel deletion
    const handleConfirmReactivate = async () => {
        if (comment.trim() === '') { // Check if comment is provided
            alert('Comment is required to reactivate the vessel.'); // Alert if comment is empty
            return;
        }

        try {
            // Send reactivate request to the backend
            const response = await axios.put(`${env.backend}/api/vessel/reactivateVessel`, {


                id: selectedVessel.id,
                status: "Reactivated",
                remarks: comment,
                updatedBy: localStorage.getItem('userFullName'),
                updatedOn: new Date(),



            });

            if (response.status === 200) {
                console.log('Vessel reactivated successfully:', selectedVessel); // Log success
                setVesselList(vesselList.filter(vessel => vessel.id !== selectedVessel.id)); // Update state to remove reactivated vessel
                handleCloseReactivateDialog();
            } else {
                console.error('Failed to reactivate the vessel:', response.status); // Log failure status

                alert('Failed to reactivate the vessel.');
            }
        } catch (error) {
            console.error('Error occurred while deleting the vessel:', error);
            alert('An error occurred while deleting the vessel.');
        }
    };

    // Filter vessel list based on the search query
    const filteredVesselList = vesselList.filter(vessel =>
        (String(vessel.vesselName || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.companyName || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.classId || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.imoNumber || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.companyId || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.createdBy || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.vesselName || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.vesselName || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.lengthBreadthWidth || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.portOfRegistry || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.keelLaidDate || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.remarks || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.distinctiveNoOfLetters || '').toLowerCase().includes(search.toLowerCase())) ||
        (String(vessel.dateOfConstruction || '').toLowerCase().includes(search.toLowerCase()))
    );

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 className="titlereg" style={{ marginLeft: '-21px' }}>Delete Vessels</h1>

                                            <Paper>
                                                <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                    <TextField
                                                        className="search-bar"
                                                        label="Search"
                                                        variant="outlined"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Search />
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            }
                                                        }}
                                                    />

                                                </div>
                                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} className="table-container">
                                                    <Table>
                                                        <TableHead className="table-header">
                                                            <TableRow>
                                                                <TableCell className='table-head-new' style={{ width: "21.25%" }}>Class ID</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "21.25%" }}>Name</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "21.25%" }}>Client</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "21.25%" }}>IMO No</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "15%" }}>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filteredVesselList.map((row) => (
                                                                <TableRow sx={{
                                                                    height: '24px', '& td': {
                                                                        padding: '0 8px', height: '24px',
                                                                    }
                                                                }} key={row.id}>
                                                                    <TableCell className="table-cell-survey">{row.classId}</TableCell>
                                                                    <TableCell className='table-cell-survey' title={(row.vesselName?.length > 15) ? row.vesselName : ''}>
                                                                        {row.vesselName?.length > 15 ? `${row.vesselName.slice(0, 15)}...` : row.vesselName}
                                                                    </TableCell>
                                                                    <TableCell className="table-cell-survey">{row.companyName}</TableCell>
                                                                    <TableCell className='table-cell-survey' title={(row.imoNumber?.length > 15) ? row.imoNumber : ''}>
                                                                        {row.imoNumber?.length > 15 ? `${row.imoNumber.slice(0, 15)}...` : row.imoNumber}
                                                                    </TableCell>
                                                                    <TableCell className="table-cell-survey">
                                                                        <div className="action-buttons">
                                                                            <Tooltip title={"View Vessel Details"} >
                                                                                <IconButton sx={{ padding: '2px' }} className="action-button" onClick={() => handleViewVessel(row)}>
                                                                                    <Visibility />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title={"Reactivate Vessel"} >
                                                                                <IconButton sx={{ padding: '2px' }} className="action-button" onClick={() => handleCancelledVessels(row)}>
                                                                                    <LocalActivityIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>

                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
            <Dialog open={openReactivateDialog} onClose={handleCloseReactivateDialog}>
                <DialogTitle style={{ fontSize: '16px', fontWeight:'bold' }}>Confirm Reactivate</DialogTitle>
                <DialogContent>
                    <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight:'bold' }}>
                        Do you want to reactivate this vessel?
                        {/* Please provide a reason in the comment box below. */}
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleChangeSelect}
                                color="primary"
                            />
                        }
                        label="Would you like to reactivate all the surveys and certificates associated with it?"
                    />
                    {/* <TextField
                        autoFocus
                        id="outlined-multiline-static"

                        multiline
                        label="Comment"
                        type="text"
                        fullWidth

                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{
                            '& .MuiInputLabel-root': {
                                fontFamily: 'Segoe UI Historic',
                            },
                            '& .MuiInputLabel-shrink': {
                                marginTop: '0px',
                            },
                            '& .MuiOutlinedInput-input': {
                                fontFamily: 'Segoe UI Historic',
                            }
                        }}
                    /> */}
                    <DialogActions>
                        <div className="btn-container-multiple" style={{ marginRight: '-7px', marginTop: '0px' }}>

                            <button
                                variant="contained" className="modal-submit-btn" onClick={handleConfirmReactivate}>
                                Confirm
                            </button>
                            <button
                                className="modal-close-btn" variant="contained" onClick={handleCloseReactivateDialog}>
                                Cancel
                            </button>
                        </div>
                    </DialogActions>
                </DialogContent>
            </Dialog>

        </div>



    );
};

export default CancelledVessels;
