

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';



const AddDepartment = () => {



    const navigate = useNavigate();
    const location = useLocation();
    const goback = location.state?.from?.pathname || "/";
    const from = location.state?.from?.pathname || "/signin"

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [validName, setValidName] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [checkMatch, setCheckMatch] = useState(false)
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [surveycompany, setSurveyCompany] = useState('');
    const [error, setError] = useState('');

    const handleBack = (id) => {
        navigate('/department');
    };


    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper" style={{ minHeight: '50vh' }}>
                                        <div >
                                            <h1  class="titlereg ph-view">Create Department</h1>
                                            <div>
                                                <form class="formregister">

                                                    {/* <p class="messagereg">Signup now and get full access to our app. </p> */}

                                                    <label>

                                                        <input id='name'
                                                            placeholder="Name" type="text" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setName(e.target.value) }}
                                                            value={name}
                                                            required
                                                        />


                                                        {/* <span>Firstname</span> */}

                                                    </label>



                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="rolcodees" id="select-labl" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Code</InputLabel>


                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI' } }} id='roles' labelId="select-labl" label="code"
                                                            onChange={(e) => setCode(e.target.value)}>
                                                            {console.log(code)}
                                                            <MenuItem value='' disabled selected>Select a Role</MenuItem>
                                                            <MenuItem value='ADMIN'>Administrator</MenuItem>
                                                            <MenuItem value='USER'>Template Creator</MenuItem>
                                                            <MenuItem value='SURVEYOR'>Surveyor</MenuItem>
                                                            <MenuItem value='PROJECTMANAGER'>Project Manager</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    <label>

                                                        <input id='description'
                                                            placeholder="Description" type="text" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setDescription(e.target.value) }}
                                                            value={description}
                                                            required
                                                        />


                                                        {/* <span>Firstname</span> */}

                                                    </label>

                                                    <FormControl fullWidth>
                                                        {/* <label htmlFor='surveycompany'>SurveyCompany</label> */}
                                                        <InputLabel htmlFor='category' id="select-label" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Category</InputLabel>

                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI' } }}
                                                            labelId="select-label"
                                                            id="category"

                                                            label="Category"
                                                            onChange={(e) => setCategory(e.target.value)}
                                                        >


                                                            <MenuItem value='' disabled selected>Select</MenuItem >
                                                            <MenuItem value='HEADOFFICE'>HEADOFFICE</MenuItem >
                                                            <MenuItem value='KOCHI'>KOCHI</MenuItem >
                                                            <MenuItem value='DUBAI'>DUBAI</MenuItem >
                                                        </Select>

                                                    </FormControl>



                                                    {/* <button class="submitreg">Save</button>
    <p class="signin">Already have an acount ? <a href="#">Signin</a> </p> */}
                                                </form>
                                            </div>
                                        </div>
                                        {/* <button type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false}>Submit</button>  */}
                                        {showMessage && <div><b><green>New profile created successfully!!!</green></b></div>}
                                        <div class="btn-container-multiple">
                                            <button class="modal-submit-btn" type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false}  ><b>Save</b></button>

                                            <button style={{ marginRight: "23px" }} onClick={handleBack} className="modal-close-btn" ><b>Close</b></button>
                                        </div>
                                        {/* <span>
                                        <Link to="/Signin">Sign In</Link>
                                    </span> */}
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>


    )
}

export default AddDepartment
