import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../Css/surveydashboard.css';
import '../../Css/survey.css';
import { PieChart } from 'recharts';
import ENV from "../../config";
import Loader from '../../Components/Loader';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Header from '../../Header/Header';
import LeftNav from '../../Components/LeftNav/LeftNav';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Tooltip } from "@mui/material";
import LoaderDashboard from '../../Components/LoaderDashboard';
import { Icon, IconButton, ThemeProvider, createTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
  OutlinedInput, Button, Grid, Typography
} from '@mui/material';
import Snackbars from '../../Components/Snackbar';
import env from '../../config';
function SurveyDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [surveyId, setSurveyId] = useState();
  const surveyMap = new Map();

  //sort surveys by creation date
  surveys
    .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
    .forEach((survey) => {
      const { companyName, ...rest } = survey;
      if (surveyMap.has(companyName)) {
        surveyMap.get(companyName).push({ companyName, ...rest });
      } else {
        surveyMap.set(companyName, [{ companyName, ...rest }]);
      }
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2);

  // Calculate pagination indices
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.from(surveyMap.entries()).slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(surveyMap.size / itemsPerPage);

  // Initialize filter options from location state
  const initialcompany = location.state?.filterOptions?.company || [];
  const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
  const initialstatus = location.state?.filterOptions?.status || [];
  const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
  const initialCountries = location.state?.filterOptions?.country || [];
  const [selectedCountries, setSelectedCountries] = useState(initialCountries);
  const initialvessel = location.state?.filterOptions?.vessel || [];
  const [selectedVessels, setSelectedVessels] = useState(initialvessel);
  const [filteredSurveys, setFilteredSurveys] = useState([]);

  // State for available options
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableVessels, setAvailableVessels] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);

  console.log("vgg", location.state);

  const [openCreateSnackbar, setOpenCreateSnackbar] = useState(location.state?.create);


  // Navigation paths
  const goback = location.state?.from?.pathname || "/";
  const createsurvey = location.state?.from?.pathname || "/create-survey";


  const [expandedCertificates, setExpandedCertificates] = useState({});

  // Snackbar close handler
  const handleCloseCreateSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCreateSnackbar(false);
  };

  // Fetch surveys and template names on component mount
  useEffect(() => {
    if (location.state?.create) {
      setOpenCreateSnackbar(true)
    }
    fetchSurveys();
    fetchTemplateNames();
    // // handleToken();

  }, []);

  // Update available options and filter surveys when filters or surveys change
  useEffect(() => {
    updateAvailableOptions();
    filterSurveys();
  }, [selectedCompanies, selectedStatuses, selectedCountries, selectedVessels, surveys]);

  // Navigation handler
  const handleClose = () => {
    console.log("Go back from survey dashboard", goback);
    navigate(goback);
  };

  // Navigation to create survey page with filter options
  const handleCreate = () => {
    navigate(createsurvey, {
      state: {
        filterOptions: {
          company: selectedCompanies,
          status: selectedStatuses,
          country: selectedCountries,
          vessel: selectedVessels
        }
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const ListItem = ({ item }) => {
    return <li>{item}</li>;
  };

  // Toggle certificate visibility
  const handleToggleCertificates = (surveyId) => {
    setExpandedCertificates((prevExpandedCertificates) => ({
      ...prevExpandedCertificates,
      [surveyId]: !prevExpandedCertificates[surveyId],
    }));
  };


  const fetchSurveys = async () => {
    try {
      // const response = await axios.get(ENV.backend + '/api/survey');
      let response = []
      if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
        response = await axios.get(env.backend + "/api/survey");
      }
      else {
        const userId = localStorage.getItem('userFullName')
        response = await axios.get(env.backend + "/api/survey/getSurveys/" + userId);
      }
      console.log(response)
      response.data.map(item => {
        if (item.companyName === 'Cstor Maritime') {
          console.log(item)
        }
      })
      const nonDeletedSurveys = Object.values(response.data).filter(survey => survey.surveyStatus !== "Deleted");
      setSurveys(nonDeletedSurveys);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching surveys:', error);
      setLoading(true);
      // alert("error fetching surveys")
      localStorage.clear()
      window.location.replace('/')
    }
  };

  const pieChartData = surveys.reduce((acc, survey) => {
    const { surveyStatus, count } = survey;
    acc[surveyStatus] = count;
    return acc;
  }, {});

  const pieChartOptions = {
    radius: 150,
    colors: ['#ff0000', '#00ff00', '#0000ff'],
  };


  // Extract options for filters
  const companyNames = Array.from(surveyMap.keys());
  const countryNames = Array.from(new Set(surveys.map(survey => survey.countryName)));
  const statusOptions = ["New", "In-Progress", "Completed", "Approved"];
  const vesselNames = [...new Set(surveys.map(survey => survey.vesselName))];

  useEffect(() => {
    filterSurveys();
  }, [selectedCompanies, selectedStatuses, selectedCountries, selectedVessels]);

  const handleCompanyChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatuses(event.target.value);
  };
  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value);
  };
  const handleVesselChange = (event) => {
    setSelectedVessels(event.target.value);
  };

  // Update available options based on selected filters
  const updateAvailableOptions = () => {
    let filteredCountries = [];
    let filteredVessels = [];
    let filteredStatuses = [];

    surveys.forEach((survey) => {
      if (selectedCompanies.length === 0 || selectedCompanies.includes(survey.companyName)) {
        if (!filteredCountries.includes(survey.countryName)) {
          filteredCountries.push(survey.countryName);
        }
        if (!filteredVessels.includes(survey.vesselName)) {
          filteredVessels.push(survey.vesselName);
        }
        survey.surveyCertificateList.forEach(cert => {
          if (!filteredStatuses.includes(cert.surveyCertificateStatus)) {
            filteredStatuses.push(cert.surveyCertificateStatus);
          }
        });
      }
    });

    setAvailableCountries(filteredCountries);
    setAvailableVessels(filteredVessels);
    setAvailableStatuses(filteredStatuses);
  };
  // Filter surveys based on selected filters
  const filterSurveys = () => {
    let surveys = [];

    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company => {
        if (surveyMap.has(company)) {
          surveys.push(...surveyMap.get(company));
        }
      });
    } else {
      surveyMap.forEach((value) => {
        surveys.push(...value);
      });
    }

    if (selectedStatuses.length > 0) {
      surveys = surveys.filter(survey =>
        survey.surveyCertificateList.some(cert =>
          selectedStatuses.includes(cert.surveyCertificateStatus)
        )
      );
    }

    if (selectedCountries.length > 0) {
      surveys = surveys.filter(survey =>
        selectedCountries.includes(survey.countryName)
      );
    }
    if (selectedVessels.length > 0) {
      surveys = surveys.filter(survey =>
        selectedVessels.includes(survey.vesselName)
      );
    }
    surveys = surveys.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

    console.log("Filtered surveys", surveys);
    setFilteredSurveys(surveys);
  };
  const [expandedCertIndex, setExpandedCertIndex] = useState(null);

  // Filter certificates based on selected statuses
  const getFilteredCertificates = (certificates) => {
    if (selectedStatuses.length === 0) {
      return certificates;
    }
    return certificates.filter(cert => selectedStatuses.includes(cert.surveyCertificateStatus));
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // States for template management
  const [templateNames, setTemplateNames] = useState([]);
  const [initialTemplates, setInitialTemplates] = useState([]);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [OpenCertificateDialog, setOpenCertificateDialog] = useState(false);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [selectedTemplateNames, setSelectedTemplateNames] = useState([]);
  const [templateSubmitted, setTemplateSubmitted] = useState(false);
  const allowedTemplateNames = [
    "NCS-CL-PR-NEW",
    "NCS-BC-PR-NEW",
    "NCS-CRT-PR-NEW",
    "NCS-IA-PR-NEW",
    "NCS-IBC-PR-NEW",
    "DEMOCC"
  ];

  // Fetch template names from API
  // const fetchTemplateNames = async () => {
  //   try {
  //     const templateNamesResponse = await axios.get(ENV.backend + "/api/template");
  //     const templates = Object.values(templateNamesResponse.data);
  //     console.log('Fetched Template Names:', templates);
  //     setTemplateNames(templates);
  //   } catch (error) {
  //     console.error("Error fetching template names:", error);
  //   }
  // };
  const fetchTemplateNames = async () => {
    try {
      const templateNamesResponse = await axios.get(ENV.backend + "/api/template");
      let templates = Object.values(templateNamesResponse.data);

      // Filter the templates to include only the allowed names
      templates = templates.filter(template =>
        allowedTemplateNames.includes(template.templateName)
      );

      console.log('Filtered Template Names:', templates);
      setTemplateNames(templates);
    } catch (error) {
      console.error("Error fetching template names:", error);
    }
  };




  // Handle selection of survey and update template names
  const handleSurveySelection = (surveyId) => {
    console.log("ab", surveyId)
    const selectedSurveyId = surveyId;
    const optionSelectedSurvey = surveys.find((surveyCertificateItem) => surveyCertificateItem.surveyId === selectedSurveyId);

    if (optionSelectedSurvey) {
      const newlySelectedTemplateNames = optionSelectedSurvey.surveyCertificateList.map(
        (certificate) => ({ templateName: certificate.certificateName })

      );
      console.log("sdhbsbjh", optionSelectedSurvey, newlySelectedTemplateNames)
      const updatedLeftTemplates = templateNames.filter((template) => {
        return !newlySelectedTemplateNames.some(
          (selectedTemplate) => selectedTemplate.templateName === template.templateName
        );
      });

      setTemplateNames(updatedLeftTemplates);
      setSelectedSurveys(optionSelectedSurvey)

      console.log('Selected Template Names:', newlySelectedTemplateNames);
      // setSelectedTemplateNames(newlySelectedTemplateNames);
      setInitialTemplates(newlySelectedTemplateNames)
    }

    return optionSelectedSurvey;
  };

  // State for managing template submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedTemplates, setSubmittedTemplates] = useState(new Set());

  // Handle template submission
  const handleSubmit = () => {
    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);

    console.log('Submitting selected templates:', selectedTemplateNames, selectedSurveys);

    const temp = selectedTemplateNames.filter((survey) => {
      return !initialTemplates.some((item) => item.templateName === survey.templateName);
    });

    const filteredTemp = temp.filter((survey) => {
      return !initialTemplates.some((initialSurvey) => initialSurvey.templateName === survey.certificateName);
    });

    const newTemplates = filteredTemp.filter(template => !submittedTemplates.has(template.templateId));

    if (newTemplates.length === 0) {
      setIsSubmitting(false);
      return;
    }

    const selectedTemplateNamesArray = newTemplates
      .filter((value) => value.templateName !== null)
      .map((value) => ({
        templateId: parseInt(value.templateId),
        certificateName: value.templateName,
        surveyCertificateStatus: "New",
        createdOn: new Date(),
        createdBy: localStorage.getItem('userFullName'),
        updatedOn: new Date(), // Add updatedOn timestamp
        updatedBy: localStorage.getItem('userFullName'),
        isCloned: false,
      }));
    const updatedSurvey = {
      surveyId: surveyId,
      surveyCertificateList: selectedTemplateNamesArray,
      updatedOn: new Date(), // Add updatedOn timestamp
      updatedBy: localStorage.getItem('userFullName'),
    };

    axios.post(ENV.backend + '/api/survey/' + surveyId, updatedSurvey)
      .then(response => {
        console.log('Survey updated successfully:', response.data);
        setTemplateSubmitted(true);
        setSubmittedTemplates(prev => new Set([...prev, ...newTemplates.map(t => t.templateId)]));
      })
      .catch(error => {
        console.error('Error updating survey:', error);
        setTemplateSubmitted(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };


  // Handle template dialog open
  const handleTemplateButtonClick = (surveyId) => {
    console.log('Selected Templates:', selectedTemplateNames);
    setOpenTemplateDialog(true);
    setSurveyId(surveyId);
    handleSurveySelection(surveyId)
  };
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [selectedCertificates, setSelectedCertificates] = useState([]);

  const handleCertificateButtonClick = (surveyId) => {
    setSelectedSurveyId(surveyId);
    const selectedSurvey = surveys.find(survey => survey.surveyId === surveyId);
    if (selectedSurvey) {
      setSelectedCertificates(getFilteredCertificates(selectedSurvey.surveyCertificateList));
    }
    setOpenCertificateDialog(true);
  };

  // Handle template dialog close
  const handleTemplateDialogClose = () => {
    console.log('Selected Templates:', selectedTemplateNames);
    setOpenTemplateDialog(false);
    reloadPageWithState();
  };

  const reloadPageWithState = () => {
    const filterOptions = {
      company: selectedCompanies,
      status: selectedStatuses,
      country: selectedCountries,
      vessel: selectedVessels,
    };
    const params = new URLSearchParams(filterOptions).toString();
    const uniqueParam = `reload=${new Date().getTime()}`;
    const newUrl = `${location.pathname}?${params}&${uniqueParam}`;
    window.location.href = newUrl;
  };

  // Update filters based on URL parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.size !== 0) {
      console.log('urlparams', urlParams.getAll('company'));
      setSelectedCompanies(urlParams.get('company') ? urlParams.get('company').split(',') : []);
      setSelectedStatuses(urlParams.get('status') ? urlParams.get('status').split(',') : []);
      setSelectedCountries(urlParams.get('country') ? urlParams.get('country').split(',') : []);
      setSelectedVessels(urlParams.get('vessel') ? urlParams.get('vessel').split(',') : []);
    }
  }, [location.search]);

  const handleCertificateDialogClose = () => {
    setOpenCertificateDialog(false);
  };

  // Handle left template names change
  const handleLeftTemplateNamesChange = (templateName) => {
    if (!selectedTemplateNames.includes(templateName)) {
      setSelectedTemplateNames([...selectedTemplateNames, templateName]);


      const updatedLeftTemplates = templateNames.filter(
        (name) => name !== templateName
      );
      setTemplateNames(updatedLeftTemplates);
    }
  };

  // Handle right template names change
  const handleRightTemplateNamesChange = (templateName) => {
    const updatedSelectedTemplates = selectedTemplateNames.filter(
      (name) => name !== templateName
    );

    setSelectedTemplateNames(updatedSelectedTemplates);


    setTemplateNames([...templateNames, templateName]);
  };


  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">

                    <div style={{ backgroundColor: '#e5fffd', borderRadius: '14px 10px 0px 0px', padding: '5px' }}>

                      <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-7px', fontSize: '26px' }}>Survey Dashboard</div></h1>

                    </div>
                    {
                      loading ? (<LoaderDashboard />) : (
                        <Paper>
                          <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                            <Grid container spacing={2} style={{ display: 'contents' }}>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Companies</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedCompanies}
                                    onChange={handleCompanyChange}
                                    input={<OutlinedInput label="Companies" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {Array.from(surveyMap.keys()).sort((a, b) => a.localeCompare(b)).map((name) => (
                                      <MenuItem key={name} value={name} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }} >
                                        <Checkbox checked={selectedCompanies.indexOf(name) > -1} />
                                        <ListItemText primary={name} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Flag</InputLabel>
                                  <Select sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedCountries}
                                    onChange={handleCountryChange}
                                    input={<OutlinedInput label="Countries" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableCountries.sort((a, b) => a.localeCompare(b)).map((name) => (
                                      <MenuItem key={name} value={name} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }} >
                                        <Checkbox checked={selectedCountries.indexOf(name) > -1} />
                                        <ListItemText primary={name} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Vessel Name</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedVessels}
                                    onChange={handleVesselChange}
                                    input={<OutlinedInput label="Vessel Name" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableVessels
                                      .slice().filter(i => i)
                                      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                      .map(id => (
                                        <MenuItem key={id} value={id} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }} >
                                          <Checkbox checked={selectedVessels.includes(id)} />
                                          <ListItemText primary={id} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                <FormControl fullWidth>
                                  <InputLabel sx={{
                                    "&.MuiInputLabel-root": {
                                      marginTop: '-11px',
                                      fontFamily: 'Segoe UI Historic'
                                    },
                                    "&.MuiInputLabel-shrink": {
                                      marginTop: '0px'
                                    }
                                  }}>Status</InputLabel>
                                  <Select
                                    sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                    multiple
                                    value={selectedStatuses}
                                    onChange={handleStatusChange}
                                    input={<OutlinedInput label="Status" />}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {availableStatuses.map((status) => (
                                      <MenuItem key={status} value={status} sx={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '2px',
                                        fontFamily: 'Segoe UI Historic'
                                      }} >
                                        <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                        <ListItemText primary={status} sx={{
                                          '& .MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontFamily: 'Segoe UI Historic'
                                          }
                                        }} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <div>
                              <Tooltip title="Create New Survey" arrow>
                                <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreate} />
                              </Tooltip>
                            </div>
                          </div>

                          <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} className="table-container">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell className='table-head-new'>S.No</TableCell>
                                  <TableCell className='table-head-new'>Client Name</TableCell>
                                  <TableCell className='table-head-new'>Vessel Name</TableCell>
                                  {/* <TableCell className='table-head-new'>Client Id</TableCell> */}
                                  <TableCell className='table-head-new'>Survey Name</TableCell>
                                  <TableCell className='table-head-new'>Created Date</TableCell>
                                  <TableCell className='table-head-new'>Actions</TableCell>

                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredSurveys.map((survey, index) => (
                                  <TableRow key={index}>
                                    <TableCell className="table-cell-survey">{index + 1}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.companyName}</TableCell>
                                    <TableCell className="table-cell-survey">{survey.vesselName}</TableCell>
                                    {/* <TableCell className="table-cell-survey">{survey.companyId}</TableCell> */}
                                    <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                    <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                    <TableCell className="table-cell-survey">
                                      <div style={{ width: '20%', display: 'flex', alignItems: 'center', gap: "8px" }}>
                                        <Tooltip title={"Add New Template"}>
                                          <IconButton sx={{ padding: '2px' }} onClick={() => handleTemplateButtonClick(survey.surveyId)}
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"View Certificates"} >
                                          <IconButton sx={{ padding: '2px' }} onClick={() => handleCertificateButtonClick(survey.surveyId)}>
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>

                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Dialog open={openTemplateDialog} onClose={handleTemplateDialogClose} fullWidth maxWidth="md">

                            <DialogContent>
                              <div>
                                <label className="label" htmlFor="templateNames">Template Names</label>
                                <div className="template-names-container">
                                  <div className="template-names-column left-column">
                                    <div className="temp">Template Options</div>
                                    {templateNames.map((templateName, index) => (
                                      <div
                                        key={templateName.templateId}
                                        className="template-name-item"
                                        onClick={() => handleLeftTemplateNamesChange(templateName)}
                                      >
                                        {templateName.templateName}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="template-names-column right-column">
                                    <div className="temp">Selected Templates</div>
                                    {selectedTemplateNames && selectedTemplateNames.length === 0 && (

                                      <div>
                                        {console.log('No selected templates available', selectedTemplateNames)}
                                      </div>
                                    )}
                                    {initialTemplates && initialTemplates.map((initialTemplate, index) => (
                                      <div
                                        key={index}
                                        className="selected-name-item"
                                      >
                                        {initialTemplate.templateName}
                                      </div>
                                    ))}
                                    {selectedTemplateNames && selectedTemplateNames.map((selectedName, index) => (
                                      <div
                                        key={index}
                                        className="selected-name-item"
                                        onClick={() => handleRightTemplateNamesChange(selectedName)}
                                      >
                                        {selectedName.templateName}
                                        {console.log('Selected Template:', selectedName.templateName)}
                                      </div>
                                    ))}
                                  </div>

                                </div>
                              </div>
                            </DialogContent>
                            {templateSubmitted && (
                              <h4 style={{ color: 'green', textAlign: 'center', margin: '0px' }}>Survey Modified! You Can Close Safely!</h4>
                            )}
                            <DialogActions>
                              <div className="btn-container-multiple" style={{ marginRight: '15px', marginTop: '0px' }}>
                                <button
                                  className="modal-close-btn" onClick={handleTemplateDialogClose}>
                                  Close
                                </button>
                                <button
                                  className="modal-submit-btn" onClick={handleSubmit} disabled={isSubmitting}>
                                  Save
                                </button>
                              </div>
                            </DialogActions>
                          </Dialog>



                          <Dialog open={OpenCertificateDialog} onClose={handleCertificateDialogClose} fullWidth maxWidth="md">
                            <DialogContent>

                              <Paper>
                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} >
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className='table-head-new-ex'>Certificate Name</TableCell>
                                        <TableCell className='table-head-new-ex' sx={{ paddingInlineStart: '0px !important', width: '45%' }}> Status</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell sx={{
                                          padding: '0px !important',
                                          paddingInlineStart: '10px !important',
                                          fontSize: '14px !important',
                                          borderBottom: '1px solid #e0e0e0'
                                        }}>
                                          {selectedCertificates.map((cert, i) => (
                                            <div
                                              className="certificate-name"
                                              key={i}
                                              style={{
                                                borderBottom: i < selectedCertificates.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                padding: '4px 0'
                                              }}
                                            >
                                              {cert.certificateName}
                                            </div>
                                          ))}
                                        </TableCell>
                                        <TableCell sx={{
                                          padding: '0px 10px 0px 0px !important',
                                          paddingInlineStart: '0px !important',
                                          fontSize: '14px !important',
                                          borderBottom: '1px solid #e0e0e0'
                                        }}>
                                          {selectedCertificates.map((cert, i) => (
                                            <div
                                              className="certificate-status"
                                              key={i}
                                              style={{
                                                borderBottom: i < selectedCertificates.length - 1 ? '1px solid #e0e0e0' : 'none',
                                                padding: '4px 0'
                                              }}
                                            >
                                              {cert.surveyCertificateStatus}
                                            </div>
                                          ))}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            </DialogContent>
                            <DialogActions>
                              <div className="btn-container-multiple" style={{ marginTop: '0px', marginRight: '15px' }} >
                                <button className="modal-close-btn" onClick={handleCertificateDialogClose}>
                                  Close
                                </button>
                              </div>
                            </DialogActions>
                          </Dialog>
                        </Paper>
                      )}
                  </div>
                </div>
              </div>


            </main>
          </TabPanel>
        </Tabs>
      </div>
      <Snackbars
        openSnackbar={openCreateSnackbar}
        handleCloseSnackbar={handleCloseCreateSnackbar}
        type="success"
        message={"Survey created successfully"}
      />
    </div>
  );
}

export default SurveyDashboard;
