import React, { useState, useEffect } from 'react';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './ReactivateVessel.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import axios from "axios";
import env from '../../../config';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, TextField, Tooltip } from "@mui/material";
import LoaderDashboard from '../../LoaderDashboard';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
    OutlinedInput, Button, Grid, Typography
} from '@mui/material';

const ReactivateVessel = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [surveyId, setSurveyId] = useState();
    const surveyMap = new Map();

    //sort surveys by creation date
    surveys
        .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
        .forEach((survey) => {
            const { companyName, ...rest } = survey;
            if (surveyMap.has(companyName)) {
                surveyMap.get(companyName).push({ companyName, ...rest });
            } else {
                surveyMap.set(companyName, [{ companyName, ...rest }]);
            }
        });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(2);

    // Calculate pagination indices
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const totalPages = Math.ceil(surveyMap.size / itemsPerPage);

    // Initialize filter options from location state
    const initialcompany = location.state?.filterOptions?.company || [];
    const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
    const initialstatus = location.state?.filterOptions?.status || [];
    const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
    const initialCountries = location.state?.filterOptions?.country || [];
    const [selectedCountries, setSelectedCountries] = useState(initialCountries);
    const initialvessel = location.state?.vesselData || [];
    const [selectedVessels, setSelectedVessels] = useState(initialvessel);
    const [comment, setComment] = useState('');
    // Fetch surveys and template names on component mount
    useEffect(() => {
        fetchSurveys();
        fetchTemplateNames();
        // // handleToken();

    }, []);



    const fetchSurveys = async () => {
        try {
            // const response = await axios.get(ENV.backend + '/api/survey');
            let response = []
            if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
                response = await axios.get(env.backend + "/api/survey");
            }
            else {
                const userId = localStorage.getItem('userFullName')
                response = await axios.get(env.backend + "/api/survey/getSurveys/" + userId);
            }

            const filteredSurveys = Object.values(response.data).filter(survey => survey.vesselName === selectedVessels?.vesselName);
            setSurveys(filteredSurveys);

            setLoading(false);

        } catch (error) {
            console.error('Error fetching surveys:', error);
            setLoading(true);
            // alert("error fetching surveys")
            localStorage.clear()
            window.location.replace('/')
        }
    };



    // Filter certificates based on selected statuses
    const getFilteredCertificates = (certificates) => {
        if (selectedStatuses.length === 0) {
            return certificates;
        }
        return certificates.filter(cert => selectedStatuses.includes(cert.surveyCertificateStatus));
    };

    // States for template management
    const [templateNames, setTemplateNames] = useState([]);
    const [OpenCertificateDialog, setOpenCertificateDialog] = useState(false);

    const fetchTemplateNames = async () => {
        try {
            const templateNamesResponse = await axios.get(env.backend + "/api/template");
            const templates = Object.values(templateNamesResponse.data);
            console.log('Fetched Template Names:', templates);
            setTemplateNames(templates);
        } catch (error) {
            console.error("Error fetching template names:", error);
        }
    };


    const [selectedSurveyId, setSelectedSurveyId] = useState(null);
    const [selectedCertificatesForReactivate, setSelectedCertificatesForReactivate] = useState([]);
    const [selectedCertificates, setSelectedCertificates] = useState([]);

    const handleCertificateButtonClick = (surveyId) => {
        setSelectedSurveyId(surveyId);
        const selectedSurvey = surveys.find(survey => survey.surveyId === surveyId);
        if (selectedSurvey) {
            setSelectedCertificates(getFilteredCertificates(selectedSurvey.surveyCertificateList));
        }
        setOpenCertificateDialog(true);
    };


    // Update filters based on URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.size !== 0) {
            console.log('urlparams', urlParams.getAll('company'));
            setSelectedCompanies(urlParams.get('company') ? urlParams.get('company').split(',') : []);
            setSelectedStatuses(urlParams.get('status') ? urlParams.get('status').split(',') : []);
            setSelectedCountries(urlParams.get('country') ? urlParams.get('country').split(',') : []);
            setSelectedVessels(urlParams.get('vessel') ? urlParams.get('vessel').split(',') : []);
        }
    }, [location.search]);

    const handleCertificateDialogClose = () => {
        setOpenCertificateDialog(false);
        // Step 1: Get the IDs from selectedCertificates
        const certIdsToRemove = selectedCertificates
            .map(cert => cert.surveyCertificateId)  // Extract surveyCertificateId
            .filter(certId => !selectedCertIds.includes(certId));  // Keep only those not in selectedCertIds

        // Step 2: Filter SelectedCertificatesForReactivate
        setSelectedCertificatesForReactivate(prevCertificates =>
            prevCertificates.filter(certId => !certIdsToRemove.includes(certId))  // Remove the IDs identified in Step 1
        );

    };
    const handleCloseReactivate = () => {
        setComment('');  // Clear comment field
        navigate('/cancelledVessels', {
            state: {
                search: location.state?.search
            }
        });
    }
    const handleConfirmReactivate = async () => {

        try {

            const surveysToReactivate = surveys
                .filter(survey => selectedSurveys.includes(survey.surveyId))  // Only include surveys with IDs in selectedSurveys
                .map(survey => ({
                    surveyId: survey.surveyId,
                    updatedBy: localStorage.getItem('userFullName'),
                    updatedOn: new Date(),

                    surveyCertificateList: survey.surveyCertificateList
                        .filter(cert => selectedCertificatesForReactivate.includes(cert.surveyCertificateId))  // Only include certificates with IDs in selectedCertificates
                        .map(cert => ({
                            surveyCertificateId: cert.surveyCertificateId
                        }))
                }));




            const surveyResponse = await axios.post(`${env.backend}/api/survey/reactivateSurveys`, surveysToReactivate);

            if (surveyResponse.status === 200) {
                try {

                    const vesselResponse = await axios.put(`${env.backend}/api/vessel/reactivateVessel`, {
                        id: selectedVessels.id,
                        updatedBy: localStorage.getItem('userFullName'),
                        updatedOn: new Date(),
                    });

                    if (vesselResponse.status === 200) {
                        console.log('Vessel reactivated successfully:', selectedVessels);
                        handleCloseReactivate();
                    } else {
                        console.error('Failed to reactivate the vessel:', vesselResponse.status);
                        alert('Failed to reactivate the vessel.');
                    }
                } catch (error) {
                    console.error('Error occurred while reactivating the vessel:', error);
                    alert('An error occurred while reactivating the vessel.');
                }
            } else {
                console.error('Failed to reactivate the surveys:', surveyResponse.status);
                alert('Failed to reactivate the surveys.');
            }
        } catch (error) {
            console.error('Error occurred while reactivating the surveys:', error);
            alert('An error occurred while reactivating the surveys.');
        }
    };


    const [selectedSurveys, setSelectedSurveys] = useState([]);

    useEffect(() => {
        // By default, select all surveys when the component loads
        setSelectedSurveys(surveys.map(survey => survey.surveyId));
        const allCertificateIds = surveys.flatMap(survey =>
            survey.surveyCertificateList.map(cert => cert.surveyCertificateId)
        );
        setSelectedCertificatesForReactivate(allCertificateIds);
    }, [surveys]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // If select all is checked, add all survey ids to selectedSurveys
            setSelectedSurveys(surveys.map(survey => survey.surveyId));
        } else {
            // If select all is unchecked, clear selectedSurveys
            setSelectedSurveys([]);
        }
    };

    const handleSelectSurvey = (surveyId) => {
        if (selectedSurveys.includes(surveyId)) {
            // Remove the surveyId from selectedSurveys if it's already selected
            setSelectedSurveys(selectedSurveys.filter(id => id !== surveyId));
        } else {
            // Add the surveyId to selectedSurveys if it's not selected
            setSelectedSurveys([...selectedSurveys, surveyId]);
        }
    };

    const isSelected = (surveyId) => selectedSurveys.includes(surveyId);

    const [selectedCertIds, setSelectedCertIds] = useState([]);

    useEffect(() => {
        // Select all certificates by default when the dialog opens
        setSelectedCertIds(
            selectedCertificates
                .map(cert => cert.surveyCertificateId)  // Extract IDs from selectedCertificates
                .filter(certId => selectedCertificatesForReactivate.includes(certId))  // Only keep IDs that are in SelectedCertificatesForReactivate
        );
    }, [selectedCertificates]);

    const handleSelectAllCertificates = (event) => {
        if (event.target.checked) {
            // If "Select All" is checked, add all certificate IDs to selectedCertIds
            setSelectedCertIds(selectedCertificates.map(cert => cert.surveyCertificateId));
        } else {
            // If "Select All" is unchecked, clear the selection
            setSelectedCertIds([]);
        }
    };

    const handleSelectCertificate = (surveyCertificateId) => {
        if (selectedCertIds.includes(surveyCertificateId)) {
            // Remove the surveyCertificateId if it is already selected
            setSelectedCertIds(selectedCertIds.filter(id => id !== surveyCertificateId));
        } else {
            // Add the surveyCertificateId if it is not selected
            setSelectedCertIds([...selectedCertIds, surveyCertificateId]);
        }
    };

    const isSelectedCertificates = (surveyCertificateId) => selectedCertIds.includes(surveyCertificateId);

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">

                                        <div>

                                            <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-7px', fontSize: '26px' }}>Reactivate {selectedVessels.vesselName}</div></h1>
                                            <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-23px', fontSize: '15px' }}>Client Name : {selectedVessels.companyName}</div></h1>



                                        </div>
                                        {
                                            loading ? (<LoaderDashboard />) : (
                                                <Paper style={{ minHeight: '80vh' }}>
                                                    <div className="search-bar-container" style={{ marginTop: '-23px' }}>

                                                    </div>

                                                    {surveys.length !== 0 ? (
                                                        <TableContainer component={Paper} sx={{ maxHeight: '65vh !important', overflowY: 'scroll' }} className="table-container">
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className='table-head-new'>
                                                                            <Checkbox
                                                                                color="primary"
                                                                                indeterminate={selectedSurveys.length > 0 && selectedSurveys.length < surveys.length}
                                                                                checked={selectedSurveys.length === surveys.length}
                                                                                onChange={handleSelectAll}
                                                                                inputProps={{ 'aria-label': 'select all surveys' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className='table-head-new'>S.No</TableCell>
                                                                        {/* <TableCell className='table-head-new'>Client Id</TableCell> */}
                                                                        <TableCell className='table-head-new'>Survey Name</TableCell>
                                                                        <TableCell className='table-head-new'>Created Date</TableCell>
                                                                        <TableCell className='table-head-new'>Created By</TableCell>
                                                                        <TableCell className='table-head-new'>Cancelled Date</TableCell>
                                                                        <TableCell className='table-head-new'>Cancelled By</TableCell>
                                                                        <TableCell className='table-head-new'>Certificates</TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {surveys.map((survey, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell padding="checkbox">
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={isSelected(survey.surveyId)}
                                                                                    onChange={() => handleSelectSurvey(survey.surveyId)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className="table-cell-survey">{index + 1}</TableCell>
                                                                            {/* <TableCell className="table-cell-survey">{survey.companyId}</TableCell> */}
                                                                            <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                                                            <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                                                            <TableCell className="table-cell-survey">{survey.createdBy}</TableCell>
                                                                            <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                                                            <TableCell className="table-cell-survey">{survey.createdBy}</TableCell>
                                                                            <TableCell className="table-cell-survey">
                                                                                <div style={{ width: '20%', display: 'flex', alignItems: 'center', gap: "8px" }}>

                                                                                    <Tooltip title={"View Certificates"}>
                                                                                        <IconButton sx={{ padding: '2px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleCertificateButtonClick(survey.surveyId)}>
                                                                                            <span style={{ textDecoration: 'underline' }}>{survey?.surveyCertificateList?.length}</span>
                                                                                        </IconButton>
                                                                                    </Tooltip>

                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    ) : (
                                                        <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight: 'bold' }}>
                                                            There is no associated surveys with this vessel.
                                                        </div>
                                                    )}
                                                    <div >
                                                        {/* {surveys.length !== 0 && <div style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            These are the surveys associated with this vessel.
                                                        </div>} */}
                                                        <div style={{ fontSize: '14px', marginBottom: '7px', fontWeight: 'bold' }}>
                                                            Do you want to reactivate this vessel?
                                                        </div>
                                                        {/* <TextField
                                                            autoFocus
                                                            id="outlined-multiline-static"

                                                            multiline
                                                            label="Comment"
                                                            type="text"
                                                            fullWidth

                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)}
                                                            sx={{
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                },
                                                                '& .MuiInputLabel-shrink': {
                                                                    marginTop: '0px',
                                                                },
                                                                '& .MuiOutlinedInput-input': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                }
                                                            }}
                                                        /> */}
                                                        <div className="btn-container-multiple" style={{ marginTop: '5px' }}>

                                                            <button
                                                                variant="contained" className="modal-submit-btn" onClick={handleConfirmReactivate}>
                                                                Reactivate
                                                            </button>
                                                            <button
                                                                className="modal-close-btn" variant="contained" onClick={handleCloseReactivate}>
                                                                Close
                                                            </button>
                                                        </div>

                                                    </div>

                                                    <Dialog open={OpenCertificateDialog} onClose={handleCertificateDialogClose} fullWidth maxWidth="md">
                                                        <DialogContent>

                                                            <Paper>
                                                                <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} >
                                                                    <Table stickyHeader aria-label="sticky table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className='table-head-new-ex'>
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        indeterminate={selectedCertIds.length > 0 && selectedCertIds.length < selectedCertificates.length}
                                                                                        checked={selectedCertIds.length === selectedCertificates.length}
                                                                                        onChange={handleSelectAllCertificates}
                                                                                        inputProps={{ 'aria-label': 'select all certificates' }}
                                                                                        disabled={true}

                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell className='table-head-new-ex'>Certificate Name</TableCell>
                                                                                <TableCell className='table-head-new-ex' sx={{ paddingInlineStart: '0px !important', width: '45%' }}> Status</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {selectedCertificates.map((cert, i) => (
                                                                                <TableRow key={i} selected={isSelectedCertificates(cert.surveyCertificateId)}>
                                                                                    <TableCell padding="checkbox">
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            checked={isSelectedCertificates(cert.surveyCertificateId)}
                                                                                            onChange={() => handleSelectCertificate(cert.surveyCertificateId)}
                                                                                            disabled={true}

                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell sx={{
                                                                                        padding: '0px !important',
                                                                                        paddingInlineStart: '10px !important',
                                                                                        fontSize: '14px !important',
                                                                                        borderBottom: '1px solid #e0e0e0'
                                                                                    }}>
                                                                                        {cert.certificateName}
                                                                                    </TableCell>
                                                                                    <TableCell sx={{
                                                                                        padding: '0px 10px 0px 0px !important',
                                                                                        paddingInlineStart: '0px !important',
                                                                                        fontSize: '14px !important',
                                                                                        borderBottom: '1px solid #e0e0e0'
                                                                                    }}>
                                                                                        {cert.surveyCertificateStatus}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Paper>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <div className="btn-container-multiple" style={{ marginTop: '0px', marginRight: '15px' }} >
                                                                <button className="modal-close-btn" onClick={handleCertificateDialogClose}>
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Paper>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>

        </div>
    );
}
export default ReactivateVessel;
