import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from "react-router";
const Unauthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goback = "/dashboard";
  const handleClose = () => {
    navigate(goback);
  };

  if (!localStorage.getItem('role')) {
    localStorage.clear()
    window.location.replace('/')
  }

  const headingStyle = {
    color: 'red',
    fontSize: '24px',
    fontWeight: 'bold',
    // Add more CSS properties as needed
  };
  const titleStyle = {
    margin: '0px', // Add this line to set margin to 0px
  };
  return (
    <div id='id01' className="modal">
      <div className="modal-content  animate modal-content-2 " >

        <h1 style={titleStyle}>SignIn</h1>
        {/* <Link to="/dashboard">Go to link pages</Link> */}
        <div className='App App2'>
          <h2 style={headingStyle}>Sorry you do not have access to this page</h2>
        </div>
        <div className="last-end">
          <button className="modal-close-btn" onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized