

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import './Css/pages.css';
import axios from 'axios';
import env from "./config";
import LeftNav from './Components/LeftNav/LeftNav';
import Header from './Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import handleToken from './Components/TokenHandler';
import Loader from './Components/Loader';



const Register = () => {



    const navigate = useNavigate();
    const location = useLocation();
    // Define navigation paths based on the location state
    const goback = location.state?.from?.pathname || "/";
    const from = location.state?.from?.pathname || "/signin"

    // State hooks for form fields and validation
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [validFName, setValidFName] = useState(false);
    const [validName, setValidName] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [checkMatch, setCheckMatch] = useState(false)
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [roles, setRoles] = useState('');
    const [surveycompany, setSurveyCompany] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state for submission


    useEffect(() => {
        // handleToken();
    }, []);

    /*let match;
    
       if(roles === 'HR') {
       match = '/register-hr'
       }else if (roles === 'SE'){
        match ='/register-se'
       }
       else if(roles === 'Marketers') {
        match ='/register-marketer'
       }
       const REGISTER_URL = match;*/

    // to validate First Name
    useEffect(() => {
        const NAME_REGEX = /^[A-Za-z][A-Za-z0-9-_]*$/;
        setValidFName(NAME_REGEX.test(fname));
    }, [fname]);

    // to validate combined First Name and Last Name length
    useEffect(() => {
        const combinedLength = fname.length + lname.length;
        setValidName(combinedLength >= 4 && combinedLength <= 24);
    }, [fname, lname]);

    // Effect to validate Password and check if it matches Confirm Password
    useEffect(() => {
        // eslint-disable-next-line
        const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
        const result = PWD_REGEX.test(password);
        console.log(password);
        console.log(result);
        setValidPass(result);
        setCheckMatch(password === matchPassword);
        console.log(matchPassword);
    }, [password, matchPassword]);

    // Effect to validate Email
    useEffect(() => {
        // Regex for validating email format
        const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email]);

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const payload = {
            FirstName: fname,
            LastName: lname,
            password: password,
            Company: surveycompany,
            Username: email,
            ConfirmPassword: matchPassword,
            role: roles
        };

        setLoading(true);
        // Check if all validations pass before sending the request
        if (validFName && validName && validPass && checkMatch && validEmail) {
            try {
                // Send registration request to the API
                const response = await axios.post(env.identityApi + '/api/identity/register', payload);

                

                // Do not navigate to the sign-in page after successful registration
                // navigate(from, { replace: true });
                console.log('Response:', response.data);
                // Clear form fields after successful registration
                setFName('');
                setLName('');
                setEmail('');
                setPassword('');
                setMatchPassword('');
                setSurveyCompany('');
                setRoles('');

                // Add the user to a role
                await axios.post(env.identityApi + '/api/identity/addusertorole', {
                    username: email,
                    role: roles
                });

                // Assuming you want to close the modal after successful registration
                // handleClose();
                setShowMessage(true); // Show success message
                setTimeout(() => {
                    setShowMessage(false);
                }, 7000);

            } catch (error) {
                if (error.response && error.response.status === 403) {
                    alert('User with the same email exist.');
                } else {
                    alert('There was an error processing your request. Please try again.');
                }
                console.error('Error:', error);
            }finally {
                setLoading(false); 
            }
        }
    };

    // Handler to navigate back to the previous page
    const handleClose = () => {
        navigate(goback);
    };




    return (
        <div>
            
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper" style={{ minHeight: '50vh' }}>
                                        <div >
                                            <div class="cyantitle" style={{marginBottom:'26px'}}>
                                            <h1  class="titlereg">Register</h1>
                                            </div>
                                            
                                            <div>
                                            <form className="formregister" onSubmit={handleSubmit}>
                                                    <div className="flexreg">
                                                        <label style={{ width: "50%" }}>
                                                            <input
                                                                id='fname'
                                                                placeholder="First Name"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => setFName(e.target.value)}
                                                                value={fname}
                                                                required
                                                            />
                                                            {!validFName && fname && <p className="error-message">First name must start with a letter.</p>}
                                                        </label>
                                                        <br />
                                                        <label style={{ width: "50%" }}>
                                                            <input
                                                                id='lname'
                                                                placeholder="Last Name"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => setLName(e.target.value)}
                                                                value={lname}
                                                                required
                                                            />
                                                            {!validName && (fname || lname) && (fname.length + lname.length < 4 || fname.length + lname.length > 24) && <p className="error-message">Name must be 4-24 characters long.</p>}
                                                        </label>
                                                    </div>


                                                    <label>
                                                        <input
                                                            style={{ color: '#000' }}
                                                            id='email'
                                                            placeholder="Email"
                                                            type="email"
                                                            className="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            value={email}
                                                            required 
                                                        />
                                                        {!validEmail && email && <p className="error-message"> &nbsp;&nbsp; &nbsp;&nbsp;Please enter a valid email address.</p>}
                                                    </label>
                                                    <div className="flexreg">
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor='surveycompany' id="select-label" sx={{
                                                                "&.MuiInputLabel-root": {
                                                                    marginTop: '-11px',
                                                                    fontFamily: 'Segoe UI'
                                                                },
                                                                "&.MuiInputLabel-shrink": {
                                                                    marginTop: '0px'
                                                                }
                                                            }}>Company Location</InputLabel>
                                                            <Select
                                                                sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI' } }}
                                                                labelId="select-label"
                                                                id="surveycompany"
                                                                label="SurveyCompanydd"
                                                                onChange={(e) => setSurveyCompany(e.target.value)}
                                                                value={surveycompany}
                                                            >
                                                                <MenuItem value='' className="dropdown-list">Select</MenuItem>
                                                                <MenuItem value='HEADOFFICE' className="dropdown-list">HEADOFFICE</MenuItem>
                                                                <MenuItem value='KOCHI' className="dropdown-list">KOCHI</MenuItem>
                                                                <MenuItem value='DUBAI' className="dropdown-list">DUBAI</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="roles" id="select-labl" sx={{
                                                                "&.MuiInputLabel-root": {
                                                                    marginTop: '-11px',
                                                                    fontFamily: 'Segoe UI'
                                                                },
                                                                "&.MuiInputLabel-shrink": {
                                                                    marginTop: '0px'
                                                                }
                                                            }}>User Role</InputLabel>
                                                            <Select
                                                                sx={{ borderRadius: "10px" ,"&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI' } }}
                                                                id='roles'
                                                                labelId="select-labl"
                                                                label="userroles"
                                                                onChange={(e) => setRoles(e.target.value)}
                                                                value={roles}
                                                            >
                                                                <MenuItem value='' disabled className="dropdown-list"> Select a Role</MenuItem>
                                                                {localStorage.getItem('role') === 'Admin' && (
                                                                    <MenuItem value='ADMIN' className="dropdown-list">
                                                                        Administrator
                                                                    </MenuItem>
                                                                )}
                                                                <MenuItem value='CERTPROADMIN' className="dropdown-list">CertPro Admin</MenuItem>
                                                                <MenuItem value='USER' className="dropdown-list">Template Creator</MenuItem>
                                                                <MenuItem value='SURVEYOR' className="dropdown-list">Surveyor</MenuItem>
                                                                <MenuItem value='PROJECTMANAGER' className="dropdown-list">Project Manager</MenuItem>
                                                                
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="flexreg">
                                                        <label style={{ width: "50%" }}>
                                                            <input
                                                                id='password'
                                                                placeholder="Password"
                                                                type="password"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                value={password}
                                                                required
                                                            />
                                                            {!validPass && password && <p className="error-message">Password must be 8-24 characters long and include uppercase, lowercase, number, and special character.</p>}
                                                        </label>
                                                        <br />
                                                        <label style={{ width: "50%" }}>
                                                            <input
                                                                id='matchpassword'
                                                                placeholder="Confirm Password"
                                                                type="password"
                                                                className="inputreg"
                                                                autoComplete="off"
                                                                onChange={(e) => setMatchPassword(e.target.value)}
                                                                value={matchPassword}
                                                                required
                                                            />
                                                            {!checkMatch && matchPassword && <p className="error-message">Passwords do not match.</p>}
                                                        </label>
                                                    </div>
                                                    {loading && <Loader />}
                                                    {showMessage && !loading && <div><b className="success-message"><green>New profile created successfully!!!</green></b></div>}
                                                </form>
                                            </div>
                                        </div>
                                        
                                                    <div className="btn-container-multiple">
                                                        <button
                                                            style={{ marginRight: "23px" }}
                                                            className="modal-submit-btn"
                                                            type="submit"
                                                            disabled={!email || !validName || !validPass || !checkMatch || !validEmail}
                                                            onClick={handleSubmit}
                                                        >
                                                            <b>Save</b>
                                                        </button>
                                                    </div>
                                        {/* <button type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false}>Submit</button>  */}
                                        {/* {showMessage && <div><b><green>New profile created successfully!!!</green></b></div>}
                                        <div class="btn-container-multiple">
                                            <button style={{ marginRight: "23px" }} class="modal-submit-btn" type="submit" disabled={!email || !validName || !validPass || !checkMatch || !validEmail } onClick={handleSubmit} ><b>Save</b></button>
                                        </div> */}
                                        {/* <button className="modal-close-btn" onClick={handleClose}><b>Close</b></button> */}

                                        {/* <span>
                                        <Link to="/Signin">Sign In</Link>
                                    </span> */}
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>


    )
}

export default Register
