import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import handleToken from '../Components/TokenHandler';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from '../Header/Header';


const FinalizedPdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [comment, setComment] = useState('Rejected');
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state?.rowData;
  const certeditorsurveyor = location.state?.from?.pathname || "/cert-editor-surveyor";
  const companyname = location.state.company;
  const surveyid = location.state.surveyid;
  const surveycertificateid = location.state.surveycertificateid;
  const surveycertificatename = location.state.surveycertificatename;
  const surveyname = location.state.surveyname;
  const flow = location.state.flow;
  const status = location.state.status;
  const surveys = location.state?.survey;
  const prevFlow = location.state?.prevFlow;
  const totemplatelist = location.state?.from?.pathname || "/surveyor-dashboard"

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [templateNames, setTemplateName] = useState(null);
  const [isCommentProvided, setIsCommentProvided] = useState(true);

  // API service URLs
  const APPROVE_SERVICE_URL = ENV.backend + '/api/surveyor/approve-certificate';
  const REJECT_SERVICE_URL = ENV.backend + '/api/surveyor/reject-certificate';

  useEffect(() => {
    // handleToken();
  })

  // Fetch PDF URL when component mounts or when relevant state changes
  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        console.log(surveycertificatename, companyname, surveyid, surveyname, flow);
        const response = await axios.get(ENV.backend + '/api/surveyor/finalizedpdfpreview', {
          params: {
            certificateName: surveycertificatename,
            companyName: companyname,
            surveyId: surveyid,
            surveyName: surveyname,
            flow: flow
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;

          // If on a native platform, save the PDF locally
          if (Capacitor.isNativePlatform()) {
            savePdfToFile(blob);
            console.log("Insode isnative platform");
          }

          // Create an object URL for the PDF and set it in the state
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };

    fetchPdfUrl();
  }, [surveycertificatename, companyname, surveyid, surveyname, flow]);


  // Function to save the PDF file locally on native platforms
  const savePdfToFile = async (blob) => {
    // Convert the blob to a base64 string
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1]; // Remove the data URL prefix

      // Save the PDF file locally
      const filePath = 'downloaded.pdf';
      const directory = Directory.Documents;

      await Filesystem.writeFile({
        path: filePath,
        data: base64String,
        directory,
      });

      // Open the saved PDF file using the default viewer
      openPdfFile();
    };
  };

  // Function to open the PDF file
  const openPdfFile = async () => {
    try {
      const filePathOld = "file:///storage/emulated/0/Documents/downloaded.pdf";
      await FileOpener.openFile({
        path: filePathOld,
      });
    } catch (error) {
      console.error('Error opening PDF:', error);
    }
  };

  // Function to handle approval of the certificate
  const handleApprove = () => {
    let actionSubmittedBy = "";
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else {
      actionSubmittedBy = '';
    }
    console.log("surveys and all", surveys, rowData);

    const data = {
      vesselId: surveys?.vesselId,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: rowData?.surveyCertificateId,
      surveyName: surveyname,
      surveyId: surveys?.surveyId,
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date(),
      approvedOn: new Date(),
      approvedBy: localStorage.getItem("userFullName"),
      certificateGoodName: rowData?.certificateGoodName,
      certIssueDate: new Date(rowData?.issuedDate).toISOString(),
      certExpiryDate: new Date(rowData?.expiryDate).toISOString(),
      updatedBy: localStorage.getItem("userFullName"),
      updatedOn: new Date()
    };
    console.log(data)
    const response = axios.post(APPROVE_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }
    })
      .then(() => {

        window.location.reload();
      })
      .catch(error => {
        console.error('Error approving:', error);
      });

    handleClose();
  };
  const handleReject = () => {
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the rejection modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
  };
  // Function to handle rejection of the certificate
  const handleSubmitReject = () => {
    let actionSubmittedBy = "";
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else {
      actionSubmittedBy = '';
    }
    const data = {
      companyName: companyname,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      comment: comment,
      updatedBy: localStorage.getItem("userFullName"),
      updatedOn: new Date(),
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date()
    };
    console.log(data)
    const response = axios.post(REJECT_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }
    })
      .then(() => {

        window.location.reload();
      })
      .catch(error => {
        console.error('Error rejecting:', error);
      });
    setIsModalOpen(false);
    handleClose();// Close the modal after submission

  };

  // Function to handle changes in the rejection comment
  const handleCommentChange = (event) => {
    setComment(event.target.value);
    setIsCommentProvided(event.target.value.trim() !== '');
    console.log(comment)
  };

  // Function to handle rejection of the certificate

  // Function to navigate to the previous page
  const handleClose = () => {
    navigate('/viewCertificates', {
      state: {
        status: status,
        survey: surveys,
        surveyid: surveyid,
        flow: flow,
        filterOptions: location.state?.filterOptions
      }
    });
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid container-fluid-ex">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          {/* <LeftNav /> */}
          <TabPanel style={{ width: "100%", marginRight: "2%", marginLeft: "2%" }}>
            <main>
              <div>
                <div class="active-heading" >
                  Template Name : {surveycertificatename || 'Editor'}</div>
              </div>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper " style={{ margin: "0", maxHeight: '85vh' }}>
                    <div>
                      <div className="editor-container " >


                        {pdfUrl ? (
                          <div style={{ width: '100%' }}>
                            <embed src={pdfUrl} title="PDF Preview" style={{ width: '100%', height: '77vh', border: 'none' }} />

                          </div>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                      <div class="btn-container-multiple" style={{ marginTop: '0px' }}>
                        <button style={{ height: '28.5px', padding: '0' }} className="modal-close-btn" onClick={handleClose}><b>Close</b></button>
                        {flow === "Approval" && (
                          <button style={{ height: '28.5px', padding: '0' }} className="modal-reject-btn" onClick={handleReject} disabled={!isCommentProvided}>Reject</button>
                        )}
                        {flow === "Approval" && (
                          <button style={{ height: '28.5px', padding: '0' }} className="modal-submit-btn" onClick={handleApprove}>Approve</button>
                        )}
                        {/* {flow === "Approval" && (
                          <div>
                            <label htmlFor="comment">Comment:</label>
                            <textarea
                              id="comment"
                              value={comment}
                              onChange={handleCommentChange}
                              rows={4}
                              cols={50}
                            ></textarea>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
      {isModalOpen && (
        <div className="modal-popup">
          <div className="modal-content-popup">
            <h3 style={{ textAlign: 'left' }}>Rejection Reason:</h3>
            <textarea
              id="comment"
              value={comment} className="select-template"
              placeholder='Provide a reason to reject.'
              onChange={handleCommentChange}
              rows={3}
              cols={50}
              style={{ width: "100%", height: '19vh' }}
            ></textarea>
            <div className="modal-buttons-popup">
              <button onClick={handleSubmitReject}><div style={{ marginTop: '-7.5px' }}><b>Submit</b></div></button>
              <button onClick={handleModalClose}><div style={{ marginTop: '-7.5px' }}><b>Cancel</b></div></button>
            </div>
          </div>
        </div>
      )}
    </div >
  );
};

export default FinalizedPdfPreview;
